import React, { Fragment, useState } from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { Portal } from '@headlessui/react';
import {
  CalendarIcon,
  ExternalLinkIcon,
  LocationMarkerIcon,
  MenuAlt2Icon,
  TrashIcon,
  UsersIcon,
  XIcon,
} from '@heroicons/react/outline';

import { NylasCalendarEventDataFragment } from '../../../../generated/graphql';
import { displayTime } from '../../../lib/copy';
import { getDifferenceInMinutes } from './helpers';
import IconButton from '../../../components/IconButton';
import Tooltip from '../../../components/Tooltip';
import Avatar from '../../../components/Avatar';
import Linkifier from '../../../components/Linkifier';
import linkifyHtml from 'linkify-html';
import { useAuth } from '../../../../contexts/AuthContext';
import { DEFAULT_CALENDAR_HEX_COLOR } from '../../../lib/colors';

// Add this new function at the top of the file, after the imports
const preserveLineBreaks = (text: string) => {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

export const TIME_INCREMENT_UNIT = 5;
export const TIME_INCREMENT_OFFSET = 2;

interface NylasEventItemProps {
  nylasCalendarEvent: NylasCalendarEventDataFragment;
  columnClassIndex: Record<string, string>;
  onDeleteEventClick: (
    nylasCalendarEvent: NylasCalendarEventDataFragment,
  ) => Promise<void>;
  hexColor?: string;
}

const NylasEventItem: React.FC<NylasEventItemProps> = ({
  nylasCalendarEvent,
  columnClassIndex,
  onDeleteEventClick,
  hexColor,
}) => {
  const { authedProviderUser } = useAuth();

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
  });

  if (nylasCalendarEvent.when.__typename !== 'TimespanWhen') {
    console.log(
      `[Calendar] Unsupported event type: ${nylasCalendarEvent.when.__typename}`,
    );
    return null;
  }

  // NOTE: we are not passing zone as we want to
  //  display the event in the client browser TZ.

  const startTimeFormatted = DateTime.fromISO(
    nylasCalendarEvent.when.startTime,
    // { zone: nylasCalendarEvent.when.startTimezone },
  );
  const endTimeFormatted = DateTime.fromISO(
    nylasCalendarEvent.when.endTime,
    // { zone: nylasCalendarEvent.when.endTimezone },
  );

  const eventDurationInMinutes = getDifferenceInMinutes(
    startTimeFormatted,
    endTimeFormatted,
  );

  const dayInMinutes = getDifferenceInMinutes(
    startTimeFormatted.startOf('day'),
    startTimeFormatted,
  );

  const smallScheduledItem = eventDurationInMinutes < 45;

  const startEndTime = `${displayTime(
    startTimeFormatted,
    'h:mm',
  )}–${displayTime(endTimeFormatted, 't')}`;

  const fullDateTimeString = `${startTimeFormatted.toFormat(
    'EEEE, d MMMM',
  )} ⋅ ${startEndTime}`;

  return (
    <Popover as={Fragment}>
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={classNames(
              'relative mt-px flex w-full outline-none',
              columnClassIndex[startTimeFormatted.day],
            )}
            style={{
              gridRow: `${
                TIME_INCREMENT_OFFSET + dayInMinutes / TIME_INCREMENT_UNIT
              } / span ${eventDurationInMinutes / TIME_INCREMENT_UNIT}`,
            }}
          >
            <div
              className={classNames(
                'group absolute inset-0 mr-2 mb-px flex flex-col items-start overflow-hidden rounded-md border border-white px-2 text-left text-caption leading-4 text-white transition-colors duration-300',
                !smallScheduledItem && 'py-2',
                open && 'border-none shadow-200',
              )}
              style={{
                backgroundColor: hexColor || DEFAULT_CALENDAR_HEX_COLOR,
              }}
            >
              <div className="text-small-caption font-medium">
                {nylasCalendarEvent.title}
              </div>
              <div
                className={classNames(
                  'text-small-caption',
                  'mt-0.5',
                  smallScheduledItem && 'mt-0',
                )}
              >
                {startEndTime}
              </div>
            </div>
          </Popover.Button>

          {open && (
            <Portal>
              <Popover.Panel
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className="z-[20] w-96 overflow-hidden rounded-md bg-white p-6 pb-8 shadow-400 focus:outline-none"
              >
                <div className="mb-1 flex w-full flex-row items-center justify-end">
                  {nylasCalendarEvent.organizer?.email ===
                    authedProviderUser?.email && (
                    <IconButton
                      aria-label="Delete event"
                      IconComponent={TrashIcon}
                      className="mr-1 h-7 w-7"
                      onClick={() => onDeleteEventClick(nylasCalendarEvent)}
                    />
                  )}
                  <Popover.Button
                    as={IconButton}
                    aria-label="Close event"
                    IconComponent={XIcon}
                    className="ml-1 h-7 w-7"
                  />
                </div>

                <div className="flex max-h-[300px] w-full flex-col space-y-3 overflow-y-scroll">
                  <div className="break-words text-category font-medium text-neutral-150">
                    {nylasCalendarEvent.title}
                  </div>

                  <div className="flex items-start">
                    <CalendarIcon className="mr-3 h-5 w-5 flex-shrink-0 text-neutral-150" />
                    <span className="text-caption text-neutral-150">
                      {fullDateTimeString}
                    </span>
                  </div>

                  {nylasCalendarEvent.location && (
                    <div className="flex items-start">
                      <LocationMarkerIcon className="mr-3 h-5 w-5 flex-shrink-0 text-neutral-150" />
                      <span className="text-caption text-neutral-150">
                        <Linkifier text={nylasCalendarEvent.location} />
                      </span>
                    </div>
                  )}

                  {nylasCalendarEvent.htmlLink && (
                    <div className="flex items-start">
                      <ExternalLinkIcon className="mr-3 h-5 w-5 flex-shrink-0 text-neutral-150" />
                      <a
                        href={nylasCalendarEvent.htmlLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-caption text-neutral-150"
                      >
                        See event on external calendar
                      </a>
                    </div>
                  )}

                  {nylasCalendarEvent.description && (
                    <div className="flex w-full items-start">
                      <MenuAlt2Icon className="mr-3 h-5 w-5 flex-shrink-0 text-neutral-150" />
                      <div className="min-w-0 flex-1 break-words text-caption text-neutral-125">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: linkifyHtml(
                              preserveLineBreaks(
                                nylasCalendarEvent.description,
                              ),
                            ),
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {nylasCalendarEvent.participants &&
                    nylasCalendarEvent.participants.length > 0 && (
                      <div className="flex items-start">
                        <UsersIcon
                          className={classNames(
                            'mr-3 mt-2 h-5 w-5 text-neutral-150',
                            nylasCalendarEvent.organizer ? 'mt-2' : 'mt-2',
                          )}
                        />
                        <div className="flex flex-col space-y-2">
                          {nylasCalendarEvent.organizer && (
                            <Tooltip
                              content={nylasCalendarEvent.organizer.email}
                              enabled={Boolean(
                                nylasCalendarEvent.organizer.name,
                              )}
                            >
                              <div className="flex flex-row items-center space-x-2">
                                <Avatar
                                  name={
                                    nylasCalendarEvent.organizer.name ||
                                    nylasCalendarEvent.organizer.email
                                  }
                                  size="small"
                                />
                                <div className="flex flex-col">
                                  <div className="text-caption text-neutral-150">
                                    {nylasCalendarEvent.organizer.name ||
                                      nylasCalendarEvent.organizer.email}
                                  </div>
                                  <div className="text-small-caption text-neutral-125">
                                    Organizer
                                  </div>
                                </div>
                              </div>
                            </Tooltip>
                          )}
                          {nylasCalendarEvent.participants.map(
                            (participant, index) => {
                              if (
                                participant.email ===
                                nylasCalendarEvent.organizer?.email
                              ) {
                                return null;
                              }
                              return (
                                <Tooltip
                                  content={participant.email}
                                  enabled={Boolean(participant.name)}
                                  key={index}
                                >
                                  <div className="flex flex-row items-center space-x-2">
                                    <Avatar
                                      name={
                                        participant.name || participant.email
                                      }
                                      size="small"
                                    />
                                    <div className="text-caption text-neutral-150">
                                      {participant.name || participant.email}
                                    </div>
                                  </div>
                                </Tooltip>
                              );
                            },
                          )}
                        </div>
                      </div>
                    )}
                </div>
              </Popover.Panel>
            </Portal>
          )}
        </>
      )}
    </Popover>
  );
};

export default NylasEventItem;
