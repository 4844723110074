import { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import {
  AdjustmentsIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';

import {
  NylasCalendarEventDataFragment,
  useDeleteNylasCalendarEventMutation,
  useNylasCalendarEventsQuery,
  useNylasPrimaryCalendarQuery,
  useUpdateNylasCalendarMutation,
} from '../../../../generated/graphql';

import useIncrementNowDate from '../../../hooks/useIncrementNowDate';

import Button from '../../../components/Button';
import IconButton from '../../../components/IconButton';

import DayLabel from './DayLabel';
import TimeLabel from './TimeLabel';
import TodayTimeBar from './TodayTimeBar';

import {
  hours,
  getCalendarTitle,
  getDaysFromAnchorDate,
  setTodayScrollPosition,
} from './helpers';
import { useAuth } from '../../../../contexts/AuthContext';
import NylasEventItem from './NylasEventItem';
import Spinner from '../../../svgs/Spinner';
import { useNavigate } from 'react-router-dom';
import { customToast } from '../../../components/ToastAlert/customToast';
import ConfirmDeleteModal from '../../../components/ConfirmDeleteModal';
import ColorSelector from './ColorSelector';
import { DEFAULT_CALENDAR_HEX_COLOR } from '../../../lib/colors';

const Calendar: React.FC = () => {
  const navigate = useNavigate();
  const { authedProviderUser } = useAuth();
  const nowDate = useIncrementNowDate();
  const todayTimeBarRef = useRef<HTMLLIElement>(null);

  const [isConfirmDeleteEventModalOpen, setIsConfirmDeleteEventModalOpen] =
    useState(false);
  const [selectedNylasCalendarEvent, setSelectedNylasCalendarEvent] =
    useState<NylasCalendarEventDataFragment | null>(null);

  const [updateNylasCalendar] = useUpdateNylasCalendarMutation();
  const [deleteNylasCalendarEvent] = useDeleteNylasCalendarEventMutation();

  const [anchorDate, setAnchorDate] = useState(
    nowDate.startOf('week').startOf('day'),
  );

  const oneWeekFromAnchorDate = anchorDate.plus({ weeks: 1 });
  const { weekDays, columnClassIndex } = getDaysFromAnchorDate(
    anchorDate,
    nowDate,
  );
  const showTodayTimeBar = weekDays.find((day) => day.isToday);

  const isCalendlyConnected = Boolean(
    authedProviderUser?.provider.calendlyIntegration,
  );
  const isNylasConnected = authedProviderUser?.hasNylasIntegration;

  useEffect(() => {
    setTodayScrollPosition(todayTimeBarRef);
  }, [todayTimeBarRef]);

  const {
    data: nylasPrimaryCalendarData,
    loading: isLoadingNylasPrimaryCalendar,
    refetch: refetchNylasPrimaryCalendar,
  } = useNylasPrimaryCalendarQuery({
    skip: !isNylasConnected,
  });

  const nylasPrimaryCalendar = nylasPrimaryCalendarData?.nylasPrimaryCalendar;

  const {
    data: nylasCalendarEventsData,
    loading: isLoadingNylasCalendarEvents,
    refetch: refetchNylasCalendarEvents,
  } = useNylasCalendarEventsQuery({
    variables: {
      input: {
        startTime: anchorDate,
        endTime: oneWeekFromAnchorDate,
      },
    },
    skip: !isNylasConnected,
  });

  const nylasCalendarEvents = nylasCalendarEventsData?.nylasCalendarEvents;

  const lookBackUntil = isCalendlyConnected
    ? DateTime.fromISO(
        authedProviderUser.provider.calendlyIntegration.lookBackUntil,
      )
    : undefined;

  const [isDeletingNylasCalendarEvent, setIsDeletingNylasCalendarEvent] =
    useState(false);

  const handleDeleteNylasCalendarEvent = async (
    nylasCalendarEvent: NylasCalendarEventDataFragment,
  ) => {
    try {
      setIsDeletingNylasCalendarEvent(true);
      const response = await deleteNylasCalendarEvent({
        variables: {
          input: {
            eventId: nylasCalendarEvent.id,
            calendarId: nylasCalendarEvent.calendarId,
          },
        },
      });
      if (response.data?.deleteNylasCalendarEvent) {
        await refetchNylasCalendarEvents();
        customToast.success('Event deleted');
      }
    } catch (error) {
      customToast.error('Error deleting event');
    } finally {
      setSelectedNylasCalendarEvent(null);
      setIsDeletingNylasCalendarEvent(false);
    }
  };

  const [isUpdatingNylasCalendar, setIsUpdatingNylasCalendar] = useState(false);

  const handleCalendarColorChange = async (
    calendarId: string,
    hexColor: string,
  ) => {
    try {
      setIsUpdatingNylasCalendar(true);
      const response = await updateNylasCalendar({
        variables: {
          input: {
            calendarId,
            hexColor,
          },
        },
      });
      if (response.data?.updateNylasCalendar) {
        await refetchNylasPrimaryCalendar();
        customToast.success('Calendar color updated');
      }
    } catch (error) {
      customToast.error('Error updating calendar color');
    } finally {
      setIsUpdatingNylasCalendar(false);
    }
  };

  const isLoadingCalendarEvents =
    isLoadingNylasPrimaryCalendar ||
    isLoadingNylasCalendarEvents ||
    isUpdatingNylasCalendar;

  return (
    <>
      <div className="flex h-full flex-col">
        <header className="flex flex-none items-center justify-between border-b border-neutral-75 px-0 pb-4">
          <div className="flex h-[39px] w-full flex-row items-center justify-between">
            <div className="flex flex-row items-center">
              <Button
                title="Today"
                theme="secondary"
                className="mr-4"
                size="small"
                onClick={() => {
                  setAnchorDate(nowDate.startOf('week').startOf('day'));
                  setTimeout(() => {
                    setTodayScrollPosition(todayTimeBarRef);
                  }, 50);
                }}
              />
              <IconButton
                aria-label="Previous week"
                IconComponent={ChevronLeftIcon}
                iconClassName="h-6 w-6 text-neutral-125"
                onClick={() => {
                  setAnchorDate(anchorDate.minus({ weeks: 1 }));
                }}
                // Enforce cutoff date (meant to put reasonable bounds around importing events)
                disabled={anchorDate.minus({ weeks: 1 }) < lookBackUntil}
              />
              <IconButton
                aria-label="Next week"
                IconComponent={ChevronRightIcon}
                iconClassName="h-6 w-6 text-neutral-125"
                onClick={() => {
                  setAnchorDate(oneWeekFromAnchorDate);
                }}
              />
              <h1 className="ml-4 font-sans text-subtitle-small text-green-150">
                {getCalendarTitle(anchorDate, oneWeekFromAnchorDate)}
              </h1>
            </div>
            <div className="flex flex-row items-center gap-x-2">
              {isLoadingCalendarEvents && (
                <Spinner className="mr-1.5 h-5 w-5" />
              )}
              {nylasPrimaryCalendar && (
                <ColorSelector
                  selectedColor={
                    nylasPrimaryCalendar?.hexColor ?? DEFAULT_CALENDAR_HEX_COLOR
                  }
                  onColorChange={async (color) => {
                    handleCalendarColorChange(nylasPrimaryCalendar.id, color);
                  }}
                />
              )}
              <IconButton
                aria-label="Calendar integration"
                IconComponent={AdjustmentsIcon}
                iconClassName={classNames('text-green-150 h-5 w-5 rotate-90')}
                onClick={(e) => {
                  navigate('/integrations/calendar');
                }}
              />
            </div>
          </div>
        </header>
        <div
          className={classNames(
            'isolate flex flex-auto flex-col overflow-auto bg-white',
          )}
        >
          <div className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
            <div className="sticky top-0 z-50 h-[48px] bg-white shadow-100 ring-1 ring-black ring-opacity-5 sm:pr-8">
              <div className="text-sm -mr-px hidden grid-cols-7 divide-x divide-neutral-75 border-r border-neutral-75 leading-6 text-neutral-125 sm:grid">
                <div className="col-end-1 w-14" />
                {weekDays.map((weekDayProps, index) => (
                  <DayLabel {...weekDayProps} key={`${index}-dayLabel`} />
                ))}
              </div>
            </div>
            <div className="flex flex-auto">
              <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-neutral-75" />
              <div className="grid flex-auto grid-cols-1 grid-rows-1">
                {/* Horizontal lines */}
                <div className="col-start-1 col-end-2 row-start-1 grid grid-rows-[repeat(48,minmax(2rem,1fr))] divide-y divide-neutral-75">
                  <div className="row-end-1 h-7"></div>
                  {hours.map((hour, index) => (
                    <TimeLabel hour={hour} isAM key={`${index}-timeLabelAM`} />
                  ))}
                  {hours.map((hour, index) => (
                    <TimeLabel hour={hour} isPM key={`${index}-timeLabelPM`} />
                  ))}
                </div>

                {/* Vertical lines */}
                <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-neutral-75 sm:grid sm:grid-cols-7">
                  <div className="col-start-1 row-span-full" />
                  <div className="col-start-2 row-span-full" />
                  <div className="col-start-3 row-span-full" />
                  <div className="col-start-4 row-span-full" />
                  <div className="col-start-5 row-span-full" />
                  <div className="col-start-6 row-span-full" />
                  <div className="col-start-7 row-span-full" />
                  <div className="col-start-8 row-span-full w-8" />
                </div>

                {/* Events */}
                <ol
                  className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                  style={{
                    gridTemplateRows:
                      '1.75rem repeat(288, minmax(0, 1fr)) auto',
                  }}
                >
                  {showTodayTimeBar && (
                    <TodayTimeBar
                      todayTimeBarRef={todayTimeBarRef}
                      columnClassIndex={columnClassIndex}
                      nowDate={nowDate}
                    />
                  )}
                  {nylasCalendarEvents?.map((nylasCalendarEvent, index) => (
                    <NylasEventItem
                      key={`${index}-nylasCalendarEvent`}
                      nylasCalendarEvent={nylasCalendarEvent}
                      columnClassIndex={columnClassIndex}
                      onDeleteEventClick={async (
                        nylasCalendarEvent: NylasCalendarEventDataFragment,
                      ) => {
                        setSelectedNylasCalendarEvent(nylasCalendarEvent);
                        setIsConfirmDeleteEventModalOpen(true);
                      }}
                      hexColor={nylasPrimaryCalendar?.hexColor}
                    />
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        isOpen={isConfirmDeleteEventModalOpen}
        setClosed={() => setIsConfirmDeleteEventModalOpen(false)}
        performDelete={async () => {
          if (selectedNylasCalendarEvent) {
            await handleDeleteNylasCalendarEvent(selectedNylasCalendarEvent);
          }
        }}
        title="Delete event?"
        fetching={isDeletingNylasCalendarEvent}
      >
        Changes will be reflected in your external calendar and any participants
        will be notified.
      </ConfirmDeleteModal>
    </>
  );
};

export default Calendar;
