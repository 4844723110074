import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';
import {
  ABOUT_MAX_LENGTH,
  CTA_BUTTON_TEXT_MAX_LENGTH,
  CTA_FORM_LABEL_MAX_LENGTH,
  ROLE_TITLE_MAX_LENGTH,
  SERVICES_MAX_LENGTH,
  StorefrontFormData,
  TAGLINE_MAX_LENGTH,
} from '..';
import InputGroup from '../../../components/InputGroup';
import TextAreaGroup from '../../../components/TextAreaGroup';
import { useAuth } from '../../../../contexts/AuthContext';
import PractitionerBanner from '../../../components/Storefront/PractitionerBanner';
import PractitionerInfoContainer from '../../../components/Storefront/PractitionerInfoContainer';
import PractitionerInfoLeftColumn from '../../../components/Storefront/PractitionerInfoLeftColumn';
import PractitionerInfoRightColumn, {
  DEFAULT_ABOUT,
  DEFAULT_SERVICES,
  DEFAULT_TAGLINE,
} from '../../../components/Storefront/PractitionerInfoRightColumn';
import EditControls from '../../../components/EditControls';
import { resizeTextArea } from '../../../lib/form';
import { useUpdateProviderStorefrontMutation } from '../../../../generated/graphql';

const InformationModule: React.FC<{
  onSubmit: SubmitHandler<StorefrontFormData>;
  onErrors: SubmitErrorHandler<StorefrontFormData>;
  profileImageUrl?: string;
  bannerImageUrl?: string;
  bannerPosition?: number;
  onMediaChange?: () => Promise<void>;
  className?: string;
}> = ({
  onSubmit,
  onErrors,
  profileImageUrl,
  bannerImageUrl,
  bannerPosition,
  onMediaChange,
  className,
}) => {
  const { authedProviderUser } = useAuth();
  // Use account owner, because the storefront is, for now at least, only made for the account owner
  // not for each individual provider user of the account.
  const providerName = authedProviderUser?.provider?.name;
  const providerProfileImageUrl =
    profileImageUrl ??
    authedProviderUser?.provider?.ownerProfileImageMedia?.url;

  const { register, handleSubmit, watch, resetField, setValue } =
    useFormContext<StorefrontFormData>();

  const watchRoleTitle = watch('roleTitle');
  const watchCtaFormLabel = watch('ctaFormLabel');
  const watchCtaButtonText = watch('ctaButtonText');
  const watchTagline = watch('tagline');
  const watchAbout = watch('about');
  const watchServices = watch('services');

  const aboutFieldRef = useRef<HTMLTextAreaElement | null>(null);
  const servicesFieldRef = useRef<HTMLTextAreaElement | null>(null);

  const { ref: aboutRef, ...aboutRegister } = register('about', {
    maxLength: ABOUT_MAX_LENGTH,
    onBlur: () => {
      setValue('about', watch('about').trim());
      resizeTextArea(aboutFieldRef);
    },
    onChange: () => {
      resizeTextArea(aboutFieldRef);
    },
  });

  const { ref: servicesRef, ...servicesRegister } = register('services', {
    maxLength: SERVICES_MAX_LENGTH,
    onBlur: () => {
      setValue('services', watch('services').trim());
      resizeTextArea(servicesFieldRef);
    },
    onChange: () => {
      resizeTextArea(servicesFieldRef);
    },
  });

  const [inEditMode, setInEditMode] = useState(false);

  const handleSaveChanges = () => {
    handleSubmit(onSubmit, onErrors)();
    setInEditMode(false);
  };

  const handleDiscardChanges = () => {
    resetField('roleTitle');
    resetField('ctaFormLabel');
    resetField('ctaButtonText');
    resetField('tagline');
    resetField('about');
    resetField('services');
    setInEditMode(false);
  };

  const [updateProviderStorefront] = useUpdateProviderStorefrontMutation();

  const onBannerPositionChange = async (bannerPosition: number) => {
    try {
      await updateProviderStorefront({
        variables: {
          input: {
            bannerPosition: Math.round(bannerPosition),
          },
        },
      });
      await onMediaChange();
    } catch (error) {
      console.error('Failed to update banner position', error);
    }
  };

  return (
    <div
      className={classNames('relative overflow-hidden rounded-b-xl', className)}
    >
      <PractitionerBanner
        editable
        bannerImageUrl={bannerImageUrl}
        initialBannerPosition={bannerPosition}
        avatarImageUrl={providerProfileImageUrl}
        onMediaChange={onMediaChange}
        onBannerPositionChange={onBannerPositionChange}
        avatarName={providerName}
      />
      {/* Main area */}
      <PractitionerInfoContainer>
        <EditControls
          isEditing={inEditMode}
          onEditClick={() => {
            setInEditMode(true);
            setTimeout(() => {
              resizeTextArea(aboutFieldRef);
              resizeTextArea(servicesFieldRef);
            }, 0);
          }}
          handleSaveChanges={handleSaveChanges}
          handleDiscardChanges={handleDiscardChanges}
          useAbsolutePosition
        />

        {/* Left column */}
        <PractitionerInfoLeftColumn
          providerName={providerName}
          signUpEnabled={false}
          roleTitle={watchRoleTitle}
          ctaFormLabel={watchCtaFormLabel}
          ctaButtonText={watchCtaButtonText}
          inEditMode={inEditMode}
          editModeTitleSpecializationChildren={
            <InputGroup
              label="Title & Specialization"
              labelHidden
              placeholder="Title & Specialization"
              className="border-0 text-center focus:border-0 focus:outline-none"
              backgroundHidden
              maxLength={ROLE_TITLE_MAX_LENGTH}
              characterCounter
              maxLengthValue={ROLE_TITLE_MAX_LENGTH}
              currentLengthValue={watchRoleTitle?.length}
              {...register('roleTitle', { maxLength: ROLE_TITLE_MAX_LENGTH })}
            />
          }
          editModeCTAFormLabelChildren={
            <InputGroup
              label="Form label"
              labelHidden
              placeholder="Form label"
              className="border-0 text-center font-medium focus:border-0 focus:outline-none"
              backgroundHidden
              maxLength={CTA_FORM_LABEL_MAX_LENGTH}
              characterCounter
              maxLengthValue={CTA_FORM_LABEL_MAX_LENGTH}
              currentLengthValue={watchCtaFormLabel?.length}
              {...register('ctaFormLabel', {
                maxLength: CTA_FORM_LABEL_MAX_LENGTH,
              })}
            />
          }
          editModeCTAButtonTextChildren={
            <InputGroup
              label="Button text"
              labelHidden
              placeholder="Button text"
              containerClassName="mt-4"
              className="h-[44.5px] rounded-full border border-neutral-75 text-center font-medium focus:border-neutral-75 focus:outline-none"
              backgroundHidden
              maxLength={CTA_BUTTON_TEXT_MAX_LENGTH}
              characterCounter
              maxLengthValue={CTA_BUTTON_TEXT_MAX_LENGTH}
              currentLengthValue={watchCtaButtonText?.length}
              {...register('ctaButtonText', {
                maxLength: CTA_BUTTON_TEXT_MAX_LENGTH,
              })}
            />
          }
        />
        {/* Right column */}
        <PractitionerInfoRightColumn
          tagline={watchTagline}
          about={watchAbout}
          services={watchServices}
          inEditMode={inEditMode}
          editModeTaglineChildren={
            <InputGroup
              label="Tagline"
              labelHidden
              placeholder={DEFAULT_TAGLINE}
              className={classNames(
                'w-full border-0 px-0 py-0 font-serif text-title-small font-light',
                'placeholder:font-sans placeholder:text-category',
                'focus:border-0 focus:outline-none',
              )}
              backgroundHidden
              maxLength={TAGLINE_MAX_LENGTH}
              characterCounter
              maxLengthValue={TAGLINE_MAX_LENGTH}
              currentLengthValue={watchTagline?.length}
              {...register('tagline', { maxLength: TAGLINE_MAX_LENGTH })}
            />
          }
          editModeAboutChildren={
            <TextAreaGroup
              label="About"
              labelHidden
              placeholder={DEFAULT_ABOUT}
              className={classNames(
                'w-full border-0 px-0 py-0 !text-caption',
                'focus:border-0 focus:outline-none',
              )}
              backgroundHidden
              maxLength={ABOUT_MAX_LENGTH}
              maxLengthValue={ABOUT_MAX_LENGTH}
              currentLengthValue={watchAbout?.length}
              characterCounter={watchAbout?.length > ABOUT_MAX_LENGTH - 100}
              rows={3}
              {...aboutRegister}
              ref={(event) => {
                aboutRef(event);
                aboutFieldRef.current = event;
              }}
            />
          }
          editModeServicesChildren={
            <TextAreaGroup
              label="Services"
              labelHidden
              className={classNames(
                'w-full border-0 px-0 py-0 !text-caption',
                'focus:border-0 focus:outline-none',
              )}
              backgroundHidden
              placeholder={DEFAULT_SERVICES}
              maxLength={SERVICES_MAX_LENGTH}
              characterCounter={
                watchServices?.length > SERVICES_MAX_LENGTH - 100
              }
              maxLengthValue={SERVICES_MAX_LENGTH}
              currentLengthValue={watchServices?.length}
              rows={4}
              {...servicesRegister}
              ref={(event) => {
                servicesRef(event);
                servicesFieldRef.current = event;
              }}
            />
          }
        />
      </PractitionerInfoContainer>
    </div>
  );
};

export default InformationModule;
