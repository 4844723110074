import { ArchiveIcon } from '@heroicons/react/outline';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import {
  NotificationDataFragment,
  useMarkProviderUserNotificationReadMutation,
} from '../../../generated/graphql';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import {
  getNotficationContent,
  handleNotificationClick,
  ProviderUserNotificationTypename,
} from '../../lib/notifications';
import { getTimeStamp } from '../../lib/time';
import classNames from 'classnames';
import Bolt from '../../svgs/Bolt';

const NotificationItem: FC<{
  notification: NotificationDataFragment;
}> = ({ notification }) => {
  const navigate = useNavigate();

  const { refreshAuthedProviderUserNotifications } = useAuth();

  const [markProviderUserNotificationRead] =
    useMarkProviderUserNotificationReadMutation();

  const onRowClick = () =>
    handleNotificationClick({
      notification,
      markProviderUserNotificationRead,
      navigate,
      refreshAuthedProviderUserNotifications,
      skipMarkRead: false,
    });

  const onButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRowClick();
  };

  const NotificationContent = getNotficationContent(notification);
  let ActionButtons: ReactNode = (
    <Button
      size="small"
      title="View"
      theme="secondary"
      onClick={onButtonClick}
    />
  );

  switch (notification.__typename) {
    case ProviderUserNotificationTypename.ProviderUserNotificationActivation:
      ActionButtons = (
        <Button
          size="small"
          title="Send Intake"
          theme="secondary"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/${notification.programId}/follow-ups/new`);
          }}
        />
      );
      break;
    case ProviderUserNotificationTypename.ProviderUserNotificationActivityCompleted:
      break;
    case ProviderUserNotificationTypename.ProviderUserNotificationAssessmentCompleted:
      break;
    case ProviderUserNotificationTypename.ProviderUserNotificationFormNeedsProviderSignature:
      ActionButtons = (
        <Button
          size="small"
          title="Sign form"
          theme="secondary"
          onClick={onButtonClick}
        />
      );
      break;
    case ProviderUserNotificationTypename.ProviderUserNotificationComment:
      ActionButtons = (
        <Button
          size="small"
          title="Reply"
          theme="secondary"
          onClick={onButtonClick}
        />
      );
      break;
    case ProviderUserNotificationTypename.ProviderUserNotificationInvoicePaid:
      break;
    case ProviderUserNotificationTypename.ProviderUserNotificationSessionBooked:
      break;
    default:
      break;
  }

  const isStorefrontSignUpNotification =
    notification.__typename ===
    ProviderUserNotificationTypename.ProviderUserNotificationStorefrontSignUp;

  return (
    <div
      onClick={onRowClick}
      className={classNames(
        'flex w-full cursor-pointer flex-row items-center rounded-sm py-3 px-5 hover:bg-neutral-25 focus:bg-neutral-50 focus:outline-none',
        isStorefrontSignUpNotification && 'bg-green-25 hover:bg-green-50/30',
      )}
    >
      <Avatar
        size="medium"
        name={notification.patientName}
        imageUrl={notification.patientProfileImageMedia?.url}
        className="mr-3"
      />
      <div className="mr-10 flex-grow">
        <div className="mb-1 text-caption text-green-150 line-clamp-2">
          {NotificationContent}
        </div>
        <div className="text-small-caption text-neutral-110">
          {getTimeStamp(notification.createdAt, true, true)}
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex w-[3rem] items-center justify-center">
          {isStorefrontSignUpNotification && (
            <Bolt className="h-[1.125rem] w-[1.125rem] text-orange-100" />
          )}
        </div>
        {ActionButtons}
        <IconButton
          className="ml-5"
          iconClassName="w-5 h-5 text-green-100"
          IconComponent={ArchiveIcon}
          aria-label="Mark notification read"
          onClick={async (e) => {
            e.stopPropagation();
            await markProviderUserNotificationRead({
              variables: {
                providerUserNotificationId: notification.id,
              },
            });
            await refreshAuthedProviderUserNotifications();
          }}
        />
      </div>
    </div>
  );
};

export default NotificationItem;
