import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

const FirstServiceCard: FC = () => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate('/my-page/service/new')}
      className="h-[312px] w-[382px] cursor-pointer rounded-lg border border-neutral-75 bg-neutral-0 shadow-100 hover:shadow-200"
    >
      <div className="flex h-full flex-col px-8 py-6">
        {/* Icon & Price */}
        <div className="mb-4 flex w-full flex-row items-center justify-between">
          <div className="flex flex-row items-end justify-start">
            <div className="h-16 w-16 text-blue-100">
              <svg
                viewBox="0 0 48 48"
                fill="none"
                stroke="currentColor"
                strokeWidth={1.5}
              >
                <path d="M24 4L4 14L24 24L44 14L24 4Z" />
                <path d="M4 14V34L24 44M24 24V44M44 14V34L24 44" />
              </svg>
            </div>
          </div>
          <span className="text-category font-medium text-green-150">$--</span>
        </div>

        {/* Title */}
        <div className="mb-2 flex items-start justify-between gap-2">
          <h3 className="font-serif text-subtitle-small font-light text-neutral-200">
            Add your first service
          </h3>
        </div>

        {/* Duration */}
        <p className="mb-2 text-small-caption text-neutral-110">-- mins</p>

        {/* Description */}
        <p className="text-caption text-neutral-125 line-clamp-3">
          Create a new service to start accepting bookings from clients
        </p>

        {/* Bottom Actions */}
        <div className="mt-auto pt-4">
          <div className="flex items-center">
            <div className="flex gap-1.5">
              <div className="flex items-center justify-center rounded-full bg-neutral-50 px-2 py-1.5">
                <div className="h-4 w-4 text-neutral-125" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstServiceCard;
