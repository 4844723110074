import { FC, useState } from 'react';
import classNames from 'classnames';
import {
  ProgramActivityDataFragment,
  UserType,
} from '../../../generated/graphql';
import { programActivityTitle } from '../../lib/programActivity';
import {
  getProgramActivityStatus,
  ProgramActivityStatusOption,
} from '../ProgramActivityStatus';
import { ProgramActivityThumbnail } from '../ProgramActivityThumbnail';
import { getSignatureWarningMessage } from './helpers';
import { WarningMessage } from './WarningMessage';
import { CardContent } from './CardContent';
import { ProviderNote } from './ProviderNote';

interface ProgramActivityCardProps {
  programActivity: ProgramActivityDataFragment;
  userType?: UserType;
  className?: string;
  isSmallStyle?: boolean;
  isFirstCard?: boolean;
  isLastCard?: boolean;
  onClickProgramActivity: (
    programActivity: ProgramActivityDataFragment,
  ) => void;
  authedProviderUserId?: string;
  isCurrentSigner?: boolean;
}

/*
    Component for an already-created activity/resource in the context of a program.
    Backed by the ProgramActivity back-end entity and ProgramActivityDataFragment GQL type.
    Differs from the existing ActivityPreviewCard in that it has been created and has
    a backing DB entity with state and is clickable to view the activity/resource details.
*/

const ProgramActivityCard: FC<ProgramActivityCardProps> = ({
  programActivity,
  className,
  userType = UserType.ProviderUser,
  isFirstCard = false,
  isLastCard = false,
  isSmallStyle = false,
  onClickProgramActivity,
  authedProviderUserId,
  isCurrentSigner,
}) => {
  const { providerNote, voidedAt } = programActivity;
  const title = programActivityTitle(programActivity);
  const programActivityStatus = getProgramActivityStatus(
    programActivity,
    userType,
  );

  const [isHoverActive, setIsHoverActive] = useState(false);

  const isPendingSignature =
    programActivityStatus === ProgramActivityStatusOption.PendingSignature;
  const isSmallAndPendingSignature = isSmallStyle && isPendingSignature;
  const showHoverWarning = isSmallAndPendingSignature && isHoverActive;

  const signatureWarningMessage = getSignatureWarningMessage(
    programActivity,
    userType,
    authedProviderUserId,
  );

  const handleMouseEnter = () => isSmallStyle && setIsHoverActive(true);
  const handleMouseLeave = () => isSmallStyle && setIsHoverActive(false);
  const handleClick = () =>
    !isSmallAndPendingSignature && onClickProgramActivity(programActivity);

  const cardClasses = getCardClasses({
    isSmallAndPendingSignature,
    isSmallStyle,
    isLastCard,
    isPendingSignature,
    className,
  });

  return (
    <div
      className={cardClasses}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <WarningMessage
        isSmallStyle={isSmallStyle}
        isPendingSignature={isPendingSignature}
        voidedAt={voidedAt}
        isCurrentSigner={isCurrentSigner}
        programActivity={programActivity}
        userType={userType}
        authedProviderUserId={authedProviderUserId}
      />
      <div
        className={classNames(
          'flex items-center',
          !isSmallStyle && providerNote && 'border-b border-neutral-50 pb-4',
        )}
      >
        <ProgramActivityThumbnail
          programActivity={programActivity}
          className="mr-4 h-12 w-12"
        />
        <CardContent
          isSmallStyle={isSmallStyle}
          showHoverWarning={showHoverWarning}
          signatureWarningMessage={signatureWarningMessage}
          title={title}
          providerNote={providerNote}
          programActivity={programActivity}
          userType={userType}
        />
      </div>
      <ProviderNote isSmallStyle={isSmallStyle} providerNote={providerNote} />
    </div>
  );
};

const getCardClasses = ({
  isSmallAndPendingSignature,
  isSmallStyle,
  isLastCard,
  isPendingSignature,
  className,
}) => {
  return classNames(
    'w-full border-neutral-75 p-4 focus:rounded-lg focus:outline-none focus:ring-2 focus:ring-green-50',
    {
      'cursor-pointer hover:bg-neutral-25': !isSmallAndPendingSignature,
      'cursor-default': isSmallAndPendingSignature,
      'px-6': isSmallStyle,
      'border-b': isSmallStyle && !isLastCard,
      'group rounded-lg border bg-white': !isSmallStyle,
      'hover:bg-neutral-50': isPendingSignature,
    },
    className,
  );
};

export default ProgramActivityCard;
