import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import {
  useCompleteServiceCalendlyBookingMutation,
  useCompleteServiceIntakeMutation,
  useServiceIntakePackageQuery,
} from '../../../../generated/graphql';
import { usePatientAuth } from '../../../../contexts/PatientAuthContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ProgramAssessmentForm from '../CompleteProgramAssessment/ProgramAssessmentForm';
import PageContainer from '../../../components/Containers/PageContainer';
import UnauthedHeader from '../../../components/Headers/UnauthedHeader';
import useCalendly from '../../../hooks/useCalendly';
import classNames from 'classnames';
import { customToast } from '../../../components/ToastAlert/customToast';

enum IntakeStepType {
  IntakeForm = 'IntakeForm',
  EventSignUp = 'EventSignUp',
}
interface IntakeStep {
  stepType: IntakeStepType;
  completedAt?: Date | null;
}

interface IntakeState {
  stepsLoaded: boolean;
  steps: IntakeStep[];
  currentStep: IntakeStepType | null;
}

enum IntakeActionType {
  SetSteps = 'SetSteps',
  CompleteStep = 'CompleteStep',
}

type IntakeAction =
  | { type: IntakeActionType.SetSteps; steps: IntakeStep[] }
  | { type: IntakeActionType.CompleteStep; step: IntakeStepType };

const intakeReducer = (
  state: IntakeState,
  action: IntakeAction,
): IntakeState => {
  switch (action.type) {
    case IntakeActionType.SetSteps:
      // Set currentStep to the first incomplete step
      const currentStep = action.steps.find((step) => !step.completedAt);
      return {
        ...state,
        steps: action.steps,
        currentStep: currentStep?.stepType,
        stepsLoaded: true,
      };
    case IntakeActionType.CompleteStep:
      // Find the next step if there is one
      const currentStepIndex = state.steps.findIndex(
        (step) => step.stepType === action.step,
      );
      const nextStep =
        currentStepIndex !== -1 && currentStepIndex < state.steps.length - 1
          ? state.steps[currentStepIndex + 1]
          : null;
      return {
        ...state,
        // Complete the step in front-end state optimistically
        // (a call to update on the server should be called before this)
        steps: state.steps.map((step) =>
          step.stepType === action.step
            ? { ...step, completedAt: new Date() }
            : step,
        ),
        currentStep: nextStep?.stepType || null,
      };
    default:
      return state;
  }
};

const PatientIntake = () => {
  const navigate = useNavigate();
  const { authedPatient } = usePatientAuth();
  const params = useParams();
  const serviceIntakePackageId = params.serviceIntakePackageId;

  const location = useLocation();
  const locationState = location.state as {
    fullName?: string;
  };

  const {
    data: serviceIntakePackageData,
    loading: serviceIntakePackageLoading,
  } = useServiceIntakePackageQuery({
    variables: {
      serviceIntakePackageId,
    },
    onError: () => {
      customToast.error('Error retreiving service.');
      navigate('/client', { replace: true });
    },
  });

  const [completedServiceCalendlyBookingMutation] =
    useCompleteServiceCalendlyBookingMutation();

  const [completeServiceIntakeMutation] = useCompleteServiceIntakeMutation();

  useEffect(() => {
    if (serviceIntakePackageData) {
      const intakePackage = serviceIntakePackageData.serviceIntakePackage;
      dispatch({
        type: IntakeActionType.SetSteps,
        steps: [
          ...(intakePackage.calendlyEventType
            ? [
                {
                  stepType: IntakeStepType.EventSignUp,
                },
              ]
            : []),
          ...(intakePackage.intakeFormProgramActivity
            ? [
                {
                  stepType: IntakeStepType.IntakeForm,
                  completedAt:
                    intakePackage.intakeFormProgramActivity.completedAt,
                },
              ]
            : []),
        ],
      });
    }
  }, [serviceIntakePackageData]);

  const serviceIntakePackage = serviceIntakePackageData?.serviceIntakePackage;

  const [intakeState, dispatch] = useReducer(intakeReducer, {
    steps: [],
    currentStep: null,
    stepsLoaded: false,
  });

  const intakeStepsComplete =
    intakeState.stepsLoaded &&
    intakeState.steps.every((step) => step.completedAt !== null);

  const redirectToServicePageOnComplete = (intakeSuccess: boolean) => {
    navigate(
      `/p/${serviceIntakePackage?.storefrontSlug}/${serviceIntakePackage?.serviceSlug}`,
      {
        state: {
          fromIntakeSuccess: intakeSuccess,
        },
        replace: true,
      },
    );
  };

  const calendlyEmbedRef = useRef<HTMLDivElement>(null);
  const [hasCalendlyWidgetLoaded, setHasCalendlyWidgetLoaded] = useState(false);

  const onCalendlyLoaded = useCallback(async () => {
    setHasCalendlyWidgetLoaded(true);
  }, []);

  const onCalendlyBooked = useCallback(async () => {
    await completedServiceCalendlyBookingMutation({
      variables: {
        serviceIntakePackageId: serviceIntakePackage?.id,
      },
    });
    return onIntakeStepComplete(IntakeStepType.EventSignUp);
  }, [serviceIntakePackage]);

  const { initCalendlyWidget } = useCalendly(
    calendlyEmbedRef,
    onCalendlyLoaded,
    onCalendlyBooked,
  );

  // Main controller for the side effects that need to happen on step completion.
  // Keeping all of them in one effect makes it easier to reason about the control logic.
  useEffect(() => {
    const handleStepSideEffects = async () => {
      // Only run side effects if the intake package, steps, and patient are loaded
      if (serviceIntakePackage && intakeState.stepsLoaded && authedPatient) {
        if (intakeStepsComplete) {
          // If all steps are complete, complete the intake on the server
          try {
            await completeServiceIntakeMutation({
              variables: {
                serviceIntakePackageId: serviceIntakePackage.id,
              },
            });
            redirectToServicePageOnComplete(true);
          } catch (error) {
            customToast.error('Failed to complete intake.');
          }
        } else if (
          intakeState.currentStep === IntakeStepType.EventSignUp &&
          serviceIntakePackage.calendlyEventType
        ) {
          // If there is a calendly event, render Calendly widget
          initCalendlyWidget(
            serviceIntakePackage.calendlyEventType.schedulingUrl,
            {
              name: authedPatient.name,
              email: authedPatient.email,
            },
          );
        }
      }
    };

    handleStepSideEffects();
  }, [serviceIntakePackage, intakeState, authedPatient]);

  const onIntakeStepComplete = async (intakeStepType: IntakeStepType) => {
    dispatch({
      type: IntakeActionType.CompleteStep,
      step: intakeStepType,
    });
  };

  return (
    <>
      <UnauthedHeader />
      <PageContainer
        noPadding
        containerClassName="pt-16"
        loading={!serviceIntakePackageData || serviceIntakePackageLoading}
      >
        {intakeState.currentStep === IntakeStepType.EventSignUp && (
          <>
            <div
              className={classNames(
                'h-[calc(100vh-var(--top-nav-height))] w-full',
                !hasCalendlyWidgetLoaded && 'pt-64', // This pushes the Calendly-loading spinner down
              )}
              ref={calendlyEmbedRef}
            />
          </>
        )}
        {intakeState.currentStep === IntakeStepType.IntakeForm &&
          serviceIntakePackage.intakeFormProgramActivity && (
            <ProgramAssessmentForm
              authedPatient={authedPatient}
              fullNameFromIntake={locationState?.fullName}
              programActivity={serviceIntakePackage.intakeFormProgramActivity}
              onComplete={() => {
                onIntakeStepComplete(IntakeStepType.IntakeForm);
              }}
            />
          )}
      </PageContainer>
    </>
  );
};

export default PatientIntake;
