import { useState } from 'react';

import { SUPPORT_EMAIL, SUPPORT_MAIL_TO_URL } from '../lib/url';

import Button from './Button';
import Alert from './Alert';
import ConfirmDeleteModal from './ConfirmDeleteModal';

type IntegrationFeature = {
  title: string;
  description: string;
};

type IntegrationSupportSection = {
  title: string;
  content: string | React.ReactNode;
};

type IntegrationProps = {
  name: string;
  logo: React.ReactNode;
  isConnected: boolean;
  isLoading: boolean;
  CustomConnectButton?: React.ReactNode;
  onPressConnect: () => void;
  onPressDisconnect: () => Promise<unknown>;
  disconnectConfirmText?: string;
  successMessage: string | null;
  dismissSuccessMessage: () => void;
  errorMessage: string | null;
  dismissErrorMessage: () => void;
  accountUrl?: string;
  supportUrl?: string;
  supportedLanguages?: string[];
  extraSupportSections?: IntegrationSupportSection[];
  pricing: string;
  description: string | React.ReactNode;
  features: IntegrationFeature[];
  children?: React.ReactNode;
};

const Integration: React.FC<IntegrationProps> = ({
  name,
  logo,
  isConnected,
  isLoading,
  CustomConnectButton,
  onPressConnect,
  onPressDisconnect,
  disconnectConfirmText,
  successMessage,
  dismissSuccessMessage,
  errorMessage,
  dismissErrorMessage,
  accountUrl,
  supportUrl,
  supportedLanguages = ['English'],
  extraSupportSections,
  pricing,
  description,
  features,
  children,
}) => {
  const [isConfirmDisconnectModalOpen, setIsConfirmDisconnectModalOpen] =
    useState(false);

  return (
    <div className="flex items-center text-neutral-125">
      <div className="flex flex-col-reverse md:flex-row md:items-start">
        <div className="mb-5 flex flex-col items-stretch md:max-w-[170px]">
          <hr className="my-5 text-neutral-75 md:hidden" />
          <div className="flex flex-col self-start">
            <div className="mb-3 rounded border border-neutral-75 p-8">
              {logo}
            </div>

            {isConnected ? (
              <>
                {accountUrl && (
                  <Button
                    title="Your account"
                    onClick={() => window.open(accountUrl, '_blank')}
                    className="mb-3"
                  />
                )}
                <Button
                  title="Disconnect"
                  onClick={() => setIsConfirmDisconnectModalOpen(true)}
                  disabled={isLoading}
                  theme="secondary"
                />
              </>
            ) : CustomConnectButton ? (
              CustomConnectButton
            ) : (
              <Button
                title="Connect"
                onClick={onPressConnect}
                disabled={isLoading}
              />
            )}
          </div>

          <hr className="mt-5 text-neutral-75" />

          {supportedLanguages && supportedLanguages?.length > 0 && (
            <>
              <h6 className="mt-5 mb-1 font-sans text-caption font-medium text-green-150">
                Supported languages
              </h6>
              <p className="m-0 text-caption">{supportedLanguages.join(',')}</p>
            </>
          )}

          {extraSupportSections?.map((section) => (
            <div key={section.title}>
              <h6 className="mt-5 mb-1 font-sans text-caption font-medium text-green-150">
                {section.title}
              </h6>
              <p className="m-0 text-caption">{section.content}</p>
            </div>
          ))}

          <h6 className="mt-5 mb-1 font-sans text-caption font-medium text-green-150">
            Pricing
          </h6>
          <p className="m-0 text-caption">{pricing}</p>

          {supportUrl && (
            <>
              <h6 className="mt-5 mb-1 font-sans text-caption font-medium text-green-150">
                Still have questions?
              </h6>
              <p className="m-0 text-caption">
                <a
                  className="text-blue-100 underline hover:text-blue-125"
                  href={supportUrl}
                  target="_blank"
                  rel="nooopener noreferrer"
                >
                  Read our {name} FAQ
                </a>
              </p>
            </>
          )}
        </div>

        <div className="md:ml-10 md:max-w-[530px]">
          <h1 className="mb-2 font-sans text-big-label font-medium text-green-150">
            {name}
          </h1>

          <p className="text-caption">{description}</p>

          {features?.map((feature) => (
            <div key={feature.title}>
              <h6 className="mt-5 mb-1 font-sans text-caption font-medium text-green-150">
                {feature.title}
              </h6>
              <p className="m-0 text-caption">{feature.description}</p>
            </div>
          ))}

          <hr className="mt-5 text-neutral-75" />

          {errorMessage && (
            <Alert
              message={errorMessage}
              className="mt-5"
              onClickDismiss={dismissErrorMessage}
            >
              <p className="mt-1">
                Something went wrong when we tried to reach {name}. Please
                refresh and try again or{' '}
                <a
                  href={SUPPORT_MAIL_TO_URL}
                  className="text-blue-100 underline hover:text-blue-125"
                >
                  contact support
                </a>{' '}
                if the issue persists.
              </p>
            </Alert>
          )}

          {successMessage && (
            <Alert
              level="success"
              message="Success!"
              className="mt-5"
              onClickDismiss={dismissSuccessMessage}
            >
              <p
                className="mt-1"
                dangerouslySetInnerHTML={{ __html: successMessage }}
              />
            </Alert>
          )}

          {isConnected && children}

          {(errorMessage || successMessage || (isConnected && children)) && (
            <hr className="mt-5 text-neutral-75" />
          )}

          <p className="m-0 mt-5 text-caption">
            If you need help with connecting {name} with your Homecoming
            account, please reach out to us at{' '}
            <a
              href={SUPPORT_MAIL_TO_URL}
              className="text-blue-100 underline hover:text-blue-125"
            >
              {SUPPORT_EMAIL}
            </a>
            .
          </p>
        </div>
      </div>

      <ConfirmDeleteModal
        isOpen={isConfirmDisconnectModalOpen}
        title={`Are you sure you want to disconnect ${name} from Homecoming?`}
        actionButtonTitle="Disconnect"
        setClosed={() => setIsConfirmDisconnectModalOpen(false)}
        performDelete={onPressDisconnect}
        fetching={isLoading}
      >
        {disconnectConfirmText && disconnectConfirmText}
      </ConfirmDeleteModal>
    </div>
  );
};

export default Integration;
