import React, { useState } from 'react';
import classNames from 'classnames';
import { Switch } from '@headlessui/react';
import { useFormContext } from 'react-hook-form';
import {
  CheckIcon,
  DuplicateIcon,
  ExternalLinkIcon,
} from '@heroicons/react/outline';

import {
  handleCopyMyPage,
  handleSeeMyPage,
  urlFromSlug,
} from '../../../lib/storefront';

import IllustrationWeb from '../../svgs/IllustrationWeb';

import Button from '../../components/Button';

import { StorefrontFormData } from '.';

const TopBanner: React.FC<{
  storefrontSlug: string;
  updateStorefrontEnabled: (isEnabled: boolean) => Promise<void>;
}> = ({ storefrontSlug, updateStorefrontEnabled }) => {
  const storefrontUrl = urlFromSlug(storefrontSlug);

  const { watch } = useFormContext<StorefrontFormData>();

  const isStorefrontEnabled = watch('isEnabled');

  const [isCopyingLink, setIsCopyingLink] = useState(false);

  return (
    <div className="relative flex flex-col overflow-hidden rounded-2xl bg-neutral-50 py-8 px-10">
      <IllustrationWeb
        className={classNames(
          'absolute top-[-1rem] right-[-1.5rem] h-[11.875rem] w-[11.75rem] ',
          isStorefrontEnabled ? 'text-green-100/70' : 'text-neutral-100',
        )}
      />
      <div className="z-10 mb-2 text-subtitle text-neutral-150">
        Launch your page in a flash
      </div>
      <div className="z-10 mb-11 w-1/2 text-caption text-neutral-125">
        Effortlessly create your online space, streamline client
        acquisition&mdash;from discovery calls to intake forms&mdash;and enable
        easy session bookings. Simplify your setup, focus on your practice.
      </div>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-grow flex-col items-start gap-y-4 lg:flex-row lg:items-center lg:gap-x-4">
          <Button
            title="Open your page"
            subLabel={storefrontUrl}
            IconComponent={ExternalLinkIcon}
            iconPosition="left"
            theme="secondary-white"
            disabled={!isStorefrontEnabled}
            onClick={() => handleSeeMyPage(storefrontUrl)}
            circular
          />
          <Button
            title="Copy link"
            subLabel={storefrontUrl}
            IconComponent={isCopyingLink ? CheckIcon : DuplicateIcon}
            iconClassName={isCopyingLink && 'text-green-100'}
            iconPosition="left"
            theme="secondary-white"
            disabled={!isStorefrontEnabled}
            onClick={() => {
              setIsCopyingLink(true);
              handleCopyMyPage(storefrontUrl);
              setTimeout(() => {
                setIsCopyingLink(false);
              }, 3000);
            }}
            circular
          />
        </div>
        {/* Null check is needed so Switch doesn't animate on load */}
        {isStorefrontEnabled != null && (
          <div className="ml-4 flex flex-row items-center space-x-4">
            <div className="text-caption font-bold text-green-150">
              Publish to web
            </div>
            <Switch
              checked={isStorefrontEnabled}
              onChange={async () => {
                await updateStorefrontEnabled(!isStorefrontEnabled);
              }}
              className={classNames(
                !isStorefrontEnabled ? 'bg-neutral-100' : 'bg-green-100/70',
                'relative inline-flex h-[31px] w-[58px] flex-shrink-0 cursor-pointer rounded-full',
                'transition duration-200 ease-in-out focus:outline-none focus:ring-0',
              )}
            >
              <div
                className={classNames(
                  !isStorefrontEnabled ? 'translate-x-0' : 'translate-x-full',
                  'pointer-events-none absolute top-[2px] left-0.5 inline-block h-[27px] w-[27px]',
                  'transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBanner;
