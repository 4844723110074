import React, { FC, useState, useRef } from 'react';
import classNames from 'classnames';
import { PlusSmIcon } from '@heroicons/react/outline';
import { Transition } from '@headlessui/react';

import {
  ProviderUserRole,
  useUpdateProviderStorefrontMutation,
} from '../../../generated/graphql';

import { useAuth } from '../../../contexts/AuthContext';

import Spinner from '../../svgs/Spinner';
import { customToast } from '../ToastAlert/customToast';

import Avatar from '../Avatar';
import IconButton from '../IconButton';
import useIsMobileDevice from '../../hooks/useIsMobileDevice';
import { useBannerDrag } from './useBannerDrag';

enum ImageType {
  Profile = 'Profile',
  Banner = 'Banner',
}

interface ImageUploadResult {
  profileImageUrl?: string;
  bannerImageUrl?: string;
}

interface PractitionerBannerProps {
  editable?: boolean;
  avatarImageUrl?: string;
  setIsAvatarImagePopUpOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isAvatarImagePopUpOpen?: boolean;
  avatarName?: string;
  bannerImageUrl?: string;
  onMediaChange?: () => Promise<void>;
  initialBannerPosition?: number;
  onBannerPositionChange?: (position: number) => Promise<void>;
}

const PractitionerBanner: FC<PractitionerBannerProps> = ({
  editable = false,
  avatarImageUrl,
  setIsAvatarImagePopUpOpen,
  isAvatarImagePopUpOpen = false,
  avatarName,
  bannerImageUrl,
  onMediaChange,
  initialBannerPosition = 50,
  onBannerPositionChange,
}) => {
  const { authedProviderUser } = useAuth();
  const isProviderOwner = authedProviderUser?.role === ProviderUserRole.Owner;

  const [updateProviderStorefrontMutation] =
    useUpdateProviderStorefrontMutation();

  const profileImageFileInputRef = useRef<HTMLInputElement>(null);
  const bannerImageFileInputRef = useRef<HTMLInputElement>(null);

  const [isProfileImageUploadLoading, setIsProfileImageUploadLoading] =
    useState(false);
  const [isBannerImageUploadLoading, setIsBannerImageUploadLoading] =
    useState(false);

  const isMobileDevice = useIsMobileDevice();

  const {
    bannerPosition,
    handleBannerMouseDown,
    handleBannerMouseMove,
    handleBannerMouseUp,
  } = useBannerDrag({
    initialPosition: initialBannerPosition,
    onPositionChange: onBannerPositionChange,
    isEditable: editable,
  });

  const handleImageFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    imageType: ImageType,
  ) => {
    const imageFile = e.target.files?.[0];
    if (!imageFile) return;

    const imageConfig = {
      [ImageType.Profile]: {
        setLoadingState: setIsProfileImageUploadLoading,
        mutationInput: { profileImageFile: imageFile },
        successMessage: 'Profile photo updated',
        confirmUploadSuccess: (result: ImageUploadResult) =>
          Boolean(result.profileImageUrl),
      },
      [ImageType.Banner]: {
        setLoadingState: setIsBannerImageUploadLoading,
        mutationInput: { bannerImageFile: imageFile },
        successMessage: 'Banner photo updated',
        confirmUploadSuccess: (result: ImageUploadResult) =>
          Boolean(result.bannerImageUrl),
      },
    };

    const config = imageConfig[imageType];
    config.setLoadingState(true);

    try {
      const { data } = await updateProviderStorefrontMutation({
        variables: { input: config.mutationInput },
      });

      const result: ImageUploadResult | undefined =
        data?.updateProviderStorefront;

      if (result && config.confirmUploadSuccess(result) && onMediaChange) {
        await onMediaChange();
      }

      customToast.success(config.successMessage);
    } catch (err) {
      customToast.error('Something went wrong.');
    } finally {
      config.setLoadingState(false);
    }
  };

  return (
    <div className="relative">
      <div
        className={classNames(
          'relative h-36 overflow-hidden bg-cover transition-all duration-200 sm:h-48 sm:w-auto sm:rounded-b-xl md:h-64',
          !editable && 'rounded-none',
          !bannerImageUrl &&
            "bg-[url('assets/images/default-webpage-banner.png')]",
          bannerImageUrl && editable && 'cursor-move',
        )}
        onMouseDown={handleBannerMouseDown}
        onMouseMove={handleBannerMouseMove}
        onMouseUp={handleBannerMouseUp}
        onMouseLeave={handleBannerMouseUp}
      >
        {bannerImageUrl && (
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: `url(${bannerImageUrl})`,
              top: `${-bannerPosition}%`,
              height: '200%',
            }}
          />
        )}
      </div>

      {/* Upload banner image button */}
      {editable && (
        <div className="absolute top-2 right-2 z-10">
          <IconButton
            IconComponent={!isBannerImageUploadLoading ? PlusSmIcon : Spinner}
            className="h-10 w-10 justify-center !rounded-full bg-green-100/70 hover:bg-green-100"
            iconClassName="text-white h-6 w-6"
            aria-label="Change banner image"
            disabled={isBannerImageUploadLoading}
            onClick={() => {
              bannerImageFileInputRef.current?.click();
            }}
          />
          {!isBannerImageUploadLoading && (
            <input
              ref={bannerImageFileInputRef}
              type="file"
              accept="image/*"
              className="hidden"
              onChange={(e) => handleImageFileUpload(e, ImageType.Banner)}
            />
          )}
        </div>
      )}

      {/* Avatar component */}
      <div
        className={classNames(
          'absolute -bottom-10 left-1/2 -translate-x-1/2 transform',
          'md:-bottom-12 md:left-[72px] md:transform-none md:transition-all',
          'lg:left-28',
        )}
      >
        <div className="relative">
          <div
            className={classNames(
              'group relative',
              setIsAvatarImagePopUpOpen && 'cursor-pointer',
            )}
            onClick={() => !isMobileDevice && setIsAvatarImagePopUpOpen?.(true)}
          >
            <Avatar
              size={!isMobileDevice ? 'xxl' : 'xl'}
              className={classNames(
                'border-white',
                isMobileDevice ? 'border-[3.5px]' : 'border-[5px]',
              )}
              imageUrl={avatarImageUrl}
              name={avatarName}
            />
            {setIsAvatarImagePopUpOpen && (
              <div
                className={classNames(
                  'absolute inset-0 rounded-full bg-neutral-125 opacity-0 transition-opacity duration-300 group-hover:opacity-20',
                  isMobileDevice ? 'border-[3.5px]' : 'border-[5px]',
                  'border-white',
                )}
              />
            )}
          </div>
          <div className="hidden md:block">
            <Transition
              show={isAvatarImagePopUpOpen}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="fixed inset-0 z-50 flex items-center justify-center p-4 md:p-0"
            >
              <div
                className="fixed inset-0 bg-neutral-150 bg-opacity-70"
                aria-hidden="true"
              />
              <div className="popover-content relative w-full max-w-[90vmin] transform overflow-hidden rounded-lg bg-white p-1.5 transition-all md:rounded-full">
                <button
                  className="text-gray-600 hover:text-gray-800 absolute top-2 right-2 z-10"
                  onClick={() => setIsAvatarImagePopUpOpen(false)}
                >
                  &times;
                </button>
                <div className="relative aspect-square w-full">
                  <img
                    src={avatarImageUrl}
                    alt={avatarName}
                    className="absolute inset-0 h-full w-full object-cover md:rounded-full"
                  />
                </div>
              </div>
            </Transition>
          </div>
          {editable && isProviderOwner && (
            <>
              <IconButton
                IconComponent={
                  !isProfileImageUploadLoading ? PlusSmIcon : Spinner
                }
                className="absolute bottom-1 right-2 z-10 h-10 w-10 justify-center !rounded-full bg-green-100/70 hover:bg-green-100"
                iconClassName="text-white h-6 w-6"
                aria-label="Change profile image"
                disabled={isProfileImageUploadLoading}
                onClick={() => {
                  profileImageFileInputRef.current?.click();
                }}
              />
              {!isProfileImageUploadLoading && (
                <input
                  ref={profileImageFileInputRef}
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => handleImageFileUpload(e, ImageType.Profile)}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PractitionerBanner;
