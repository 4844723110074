import classNames from 'classnames';
import { ServiceIcon } from '../../../generated/graphql';
import {
  CALENDLY_COLOR_MAP,
  getIconComponent,
} from '../../components/ServiceCard/helpers';

type ServiceIconItemValue = {
  icon: ServiceIcon;
  isCalendlyDefaultColor: boolean;
  color: string;
};

const ServiceIconItem: React.FC<{
  value: ServiceIconItemValue;
}> = ({ value }) => {
  const { icon, isCalendlyDefaultColor, color } = value;

  const IconComponent = getIconComponent(icon);

  return (
    <span className="flex items-center text-caption">
      {IconComponent && (
        <IconComponent
          className={classNames(
            'h-6 w-6',
            isCalendlyDefaultColor
              ? Boolean(CALENDLY_COLOR_MAP?.[color])
                ? CALENDLY_COLOR_MAP[color]
                : 'text-blue-100'
              : 'text-blue-100',
          )}
        />
      )}
    </span>
  );
};

export default ServiceIconItem;
