import { Menu, Portal } from '@headlessui/react';
import {
  TrashIcon,
  PencilIcon,
  CalendarIcon,
  DotsHorizontalIcon,
  DocumentTextIcon,
  CashIcon,
  EyeOffIcon,
  ExclamationCircleIcon,
  DuplicateIcon,
  CheckIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import { useNavigate } from 'react-router-dom';
import {
  ProviderService,
  PatientAccessLevel,
  PublicProviderServiceDataFragment,
} from '../../../generated/graphql';
import IconButton from '../IconButton';
import { handleCopyMyPage, urlFromSlug } from '../../../lib/storefront';
import Button from '../Button';
import { CALENDLY_COLOR_MAP, getIconComponent } from './helpers';

interface ServiceCardProps {
  service?: ProviderService;
  publicService?: PublicProviderServiceDataFragment;
  handleDeleteService?: (service: ProviderService) => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  service,
  publicService,
  handleDeleteService,
}) => {
  const navigate = useNavigate();

  // Use either service or publicService data
  const serviceDisplay = service || publicService;

  const [menuButtonElement, setMenuButtonElement] =
    useState<HTMLElement | null>(null);
  const [menuPopperElement, setMenuPopperElement] =
    useState<HTMLElement | null>(null);

  const { styles: menuStyles, attributes: menuAttributes } = usePopper(
    menuButtonElement,
    menuPopperElement,
    {
      placement: 'bottom-start',
      strategy: 'absolute',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    },
  );

  const isNotAllAccess =
    serviceDisplay?.patientAccessLevel !== PatientAccessLevel.All;
  const isNewPatientsAccess =
    serviceDisplay?.patientAccessLevel === PatientAccessLevel.New;
  const isCurrentPatientsAccess =
    serviceDisplay?.patientAccessLevel === PatientAccessLevel.Current;

  const serviceUrl = urlFromSlug(serviceDisplay?.slug);

  const handleCardClick = () => {
    if (service) {
      navigate(`/my-page/service/${service.id}`);
    } else if (publicService) {
      navigate(`/p/${publicService.slug}`);
    }
  };

  const IconComponent = getIconComponent(serviceDisplay?.serviceIcon);

  const [isCopyingLink, setIsCopyingLink] = useState(false);

  return (
    <div
      className={classNames(
        'w-full cursor-pointer rounded-lg border border-neutral-75 bg-neutral-0 shadow-100 hover:shadow-200',
        service && 'h-auto md:h-[382px] md:w-[382px]',
        publicService && 'h-auto md:h-[320px] md:w-[382px]',
        service && !service?.isEnabled && 'opacity-50',
      )}
      onClick={handleCardClick}
    >
      <div className="flex h-full flex-col px-8 py-6">
        {/* Icon & Price */}
        <div className="mb-4 flex w-full flex-row items-center justify-between">
          <div className="flex flex-row items-end justify-start">
            <IconComponent
              className={classNames(
                'h-16 w-16',
                serviceDisplay.serviceIconColorUseCalendlyDefault &&
                  serviceDisplay.calendlyEventColor
                  ? CALENDLY_COLOR_MAP[serviceDisplay.calendlyEventColor]
                  : 'text-blue-100',
              )}
            />
            {isNotAllAccess && (
              <div
                className={classNames(
                  'ml-3 rounded-full px-3 py-1 text-small-caption',
                  isNewPatientsAccess && 'bg-green-25 text-green-150',
                  isCurrentPatientsAccess && 'bg-blue-25 text-blue-150',
                )}
              >
                {isNewPatientsAccess && 'New clients only'}
                {isCurrentPatientsAccess && 'Current clients only'}
              </div>
            )}
          </div>
          <span className="text-category font-medium text-green-150">
            {serviceDisplay?.isPaid && serviceDisplay?.price
              ? `$${serviceDisplay?.price.toLocaleString()}`
              : 'Free'}
          </span>
        </div>

        {/* Title and Price */}
        <div className="mb-2 flex items-start justify-between gap-2">
          <h3 className="font-serif text-subtitle-small font-light text-neutral-200">
            {serviceDisplay?.name}
          </h3>
        </div>

        {/* Duration */}
        {serviceDisplay?.durationMinutes && (
          <p className="mb-2 text-small-caption text-neutral-110">
            1 session x {serviceDisplay?.durationMinutes} minutes
          </p>
        )}

        {/* Description */}
        <p className="text-caption text-neutral-125 line-clamp-3 md:line-clamp-5">
          {serviceDisplay?.description}
        </p>

        {/* Bottom Actions */}
        {service && (
          <div className="mt-auto pt-2">
            {!service?.calendlyEventTypeUri && (
              <div className="mb-3 flex  flex-row items-center gap-2">
                <ExclamationCircleIcon className="h-5 w-5 text-orange-100" />
                <div className="text-small-caption text-neutral-125">
                  Not connected to Calendly
                </div>
              </div>
            )}

            <div className="flex items-center">
              <div className="flex gap-1.5">
                {service.calendlyEventTypeUri && (
                  <div className="flex items-center justify-center rounded-full bg-blue-25 px-2 py-1.5">
                    <CalendarIcon className="h-4 w-4 text-blue-125" />
                  </div>
                )}
                {service.isPaid && (
                  <div className="flex items-center justify-center rounded-full bg-neutral-50 px-2 py-1.5">
                    <CashIcon className="h-4 w-4 text-neutral-125" />
                  </div>
                )}
                {service.intakeFormAssessment && (
                  <div className="flex items-center justify-center rounded-full bg-purple-25 px-2 py-1.5">
                    <DocumentTextIcon className="h-4 w-4 text-green-125" />
                  </div>
                )}
                {!service.isShownOnStorefront && (
                  <div className="flex items-center justify-center rounded-full bg-orange-50 px-2 py-1.5">
                    <EyeOffIcon className="h-4 w-4 text-orange-125" />
                  </div>
                )}
              </div>

              <div className="flex-1" />

              {serviceUrl && (
                <Button
                  title="Copy link"
                  IconComponent={isCopyingLink ? CheckIcon : DuplicateIcon}
                  iconClassName={isCopyingLink && 'text-green-100'}
                  iconPosition="left"
                  size="small"
                  theme="secondary-white"
                  disabled={!service?.isEnabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsCopyingLink(true);
                    handleCopyMyPage(serviceUrl);
                    setTimeout(() => {
                      setIsCopyingLink(false);
                    }, 3000);
                  }}
                  circular
                />
              )}

              <Menu as="div" className="relative">
                <Menu.Button
                  ref={setMenuButtonElement}
                  className="flex h-8 w-8 items-center justify-center text-neutral-125 hover:text-neutral-150"
                  onClick={(e) => e.stopPropagation()}
                >
                  <IconButton
                    IconComponent={DotsHorizontalIcon}
                    className="h-6 w-6 rotate-90 text-neutral-125"
                    aria-label={'Service actions'}
                  />
                </Menu.Button>

                <Portal>
                  <Menu.Items
                    ref={setMenuPopperElement}
                    style={menuStyles.popper}
                    {...menuAttributes.popper}
                    className="z-50 w-48 divide-y divide-neutral-75 rounded-md border border-neutral-75 bg-white shadow-lg focus:outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              'flex w-full items-center px-4 py-2 text-caption',
                              active
                                ? 'bg-neutral-25 text-neutral-150'
                                : 'text-neutral-125',
                            )}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/my-page/service/${service.id}`);
                            }}
                          >
                            <PencilIcon className="mr-3 h-4 w-4" />
                            Modify service
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              'flex w-full items-center px-4 py-2 text-caption',
                              active
                                ? 'bg-neutral-25 text-red-150'
                                : 'text-red-125',
                            )}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteService?.(service);
                            }}
                          >
                            <TrashIcon className="mr-3 h-4 w-4" />
                            Remove service
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Portal>
              </Menu>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
