import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import toast from 'react-hot-toast';
import {
  useForm,
  Controller,
  SubmitHandler,
  SubmitErrorHandler,
  UseFieldArrayPrepend,
  FormProvider,
} from 'react-hook-form';

import {
  ActivityUseType,
  useAddActivityAudioMutation,
} from '../../../../generated/graphql';

import CloseX from '../../../svgs/CloseX';
import ThumbnailAudio from '../../../svgs/ThumbnailAudio';

import Modal, { MODAL_TRANSITION_DURATION } from '../../Modal';

import {
  MIN_TEXT_LENGTH,
  MAX_TITLE_LENGTH,
} from '../../../lib/form-validators';
import { onEnterKeyDown } from '../../../lib/form';
import { getDurationMSS } from '../../../lib/time';

import UploadField from '../../UploadField';
import Button from '../../Button';
import ToastAlert from '../../ToastAlert';
import InputGroup from '../../InputGroup';
import Spinner from '../../../svgs/Spinner';
import ErrorMessage from '../../ErrorMessage';
import { audioFileRegisterOptions } from '../../../lib/register-options';
import { acceptedAudioFileExtensions } from '../../../lib/media';
import { RefetchProviderActivities } from '../../../types/activity';
import { FollowUpForm } from '../../../lib/followUp';
import { removeFileExtension } from '../../../lib/copy';
import { formatProviderActivityForFollowUp } from '../../../lib/providerActivity';
import ActivityUseSettings from '../FileSettingsModal/ActivityUseSettings';

type AddAudioModalProps = {
  isModalOpen: boolean;
  isLibraryAudioTab?: boolean;
  setClosed: () => void;
  refetchProviderActivities?: RefetchProviderActivities;
  followUpMode?: boolean;
  prependToFollowUp?: UseFieldArrayPrepend<FollowUpForm, 'activities'>;
  onAdded?: (id: string) => void;
};

interface AddAudioModalFormData {
  newAudioFile: FileList | undefined;
  audioFileTitle: string;
  isOneTimeUse: boolean;
}

const AddAudioModal: FC<AddAudioModalProps> = ({
  isModalOpen,
  setClosed,
  refetchProviderActivities,
  followUpMode = false,
  prependToFollowUp,
  onAdded,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const [
    addActivityAudioMutation,
    { loading: addActivityAudioMutationLoading },
  ] = useAddActivityAudioMutation();

  const addAudioModalForm = useForm<AddAudioModalFormData>({
    mode: 'onChange',
    defaultValues: {
      audioFileTitle: '',
      isOneTimeUse: followUpMode,
    },
  });

  const {
    control,
    watch,
    register,
    getFieldState,
    setValue,
    handleSubmit,
    formState: { errors: formErrors },
    reset: resetForm,
  } = addAudioModalForm;

  const watchNewAudioFile = watch('newAudioFile');
  const watchAudioFileTitle = watch('audioFileTitle');
  const newAudioFileFieldState = getFieldState('newAudioFile');

  const onSubmit: SubmitHandler<AddAudioModalFormData> = async (
    formData: AddAudioModalFormData,
  ) => {
    setSubmitting(true);

    const addActivityAudioInput = {
      file: newAudioFile,
      title: watchAudioFileTitle,
      duration: audioDuration,
      activityUseType: formData.isOneTimeUse
        ? ActivityUseType.OneTimeUse
        : ActivityUseType.Reusable,
    };

    try {
      const { data } = await addActivityAudioMutation({
        variables: {
          input: addActivityAudioInput,
        },
      });

      if (data?.addActivityAudio) {
        const newAudioActivity = data.addActivityAudio;
        closeAndResetAddAudioModal();
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Successfully added ${newAudioActivity.title} to your ${
              followUpMode ? 'follow-up' : 'library'
            }`}
          />
        ));

        if (refetchProviderActivities) await refetchProviderActivities();

        if (followUpMode && prependToFollowUp) {
          prependToFollowUp(
            formatProviderActivityForFollowUp(newAudioActivity),
          );
        }

        onAdded?.(data?.addActivityAudio?.id);
      }
    } catch (err) {
      setSubmitting(false);
      closeAndResetAddAudioModal();
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    }
  };

  const handleErrors: SubmitErrorHandler<AddAudioModalFormData> = (errors) => {
    console.error('Errors submitting:', errors);
  };

  const closeAndResetAddAudioModal = async () => {
    setClosed();
    setTimeout(() => {
      resetForm();
      setSubmitting(false);
    }, MODAL_TRANSITION_DURATION);
  };

  const newAudioFile = watchNewAudioFile?.[0];
  const hasNewAudioFile = Boolean(newAudioFile);
  const [audioDuration, setAudioDuration] = useState<number | undefined>();
  const hasValidAudioFile = !newAudioFileFieldState.error && hasNewAudioFile;

  useEffect(() => {
    if (hasValidAudioFile && newAudioFile) {
      const audioUrl = URL.createObjectURL(newAudioFile);
      const audio = new Audio();
      audio.src = audioUrl;
      audio.addEventListener('loadedmetadata', () => {
        const duration = Math.round(audio.duration);
        setAudioDuration(duration);
        setValue('audioFileTitle', removeFileExtension(newAudioFile)); // removes file extension
      });
    }
  }, [hasValidAudioFile, newAudioFile]);

  return (
    <Modal
      name="AddAudio"
      isOpen={isModalOpen}
      setClosed={closeAndResetAddAudioModal}
      width="small"
      overflow="auto"
    >
      <div className="px-10">
        <div className="mb-6 flex w-full flex-col items-center justify-center">
          <span className="font-serif text-subtitle text-green-150">
            Add audio
          </span>
          <span className="text-caption text-green-125">
            Upload an audio file and give it a title
          </span>
        </div>

        <form className="flex w-full flex-col items-start justify-start">
          <>
            <div
              className={classNames(
                'flex h-[68px] w-[460px] items-center justify-center rounded-md bg-neutral-25 text-caption text-neutral-125 transition-colors',
                !hasValidAudioFile &&
                  'border border-dashed border-neutral-100 hover:border-green-100 hover:bg-green-25/30',
              )}
            >
              {!hasValidAudioFile ? (
                <UploadField
                  readOnly
                  acceptedFileExtensions={acceptedAudioFileExtensions.join(',')}
                  inputId="newAudioFile"
                  title="Upload MP3 or M4A file"
                  {...register('newAudioFile', audioFileRegisterOptions)}
                />
              ) : (
                <div className="flex w-full flex-row items-start justify-between p-4">
                  <div className="flex w-11/12 flex-row items-center justify-start">
                    <ThumbnailAudio className="mr-4 h-[42px] w-[42px] rounded-md" />
                    <div className="flex w-[calc(100%-58px)] flex-col items-start justify-center">
                      <span className="w-full truncate text-caption font-medium text-green-150">
                        {Boolean(watchAudioFileTitle)
                          ? watchAudioFileTitle
                          : newAudioFile?.name}
                      </span>
                      <span className="w-full truncate text-small-caption text-neutral-125">
                        {getDurationMSS(audioDuration)}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setValue('newAudioFile', undefined);
                      setValue('audioFileTitle', '');
                    }}
                  >
                    <CloseX className="cursor-pointer" />
                  </div>
                </div>
              )}
            </div>
            <ErrorMessage className="mt-2">
              {formErrors.newAudioFile?.message}
            </ErrorMessage>
            {hasNewAudioFile && (
              <FormProvider {...addAudioModalForm}>
                <Controller
                  name="audioFileTitle"
                  control={control}
                  rules={{
                    required: false,
                    minLength: {
                      value: MIN_TEXT_LENGTH,
                      message: `Title should be at least ${MIN_TEXT_LENGTH} characters long`,
                    },
                    maxLength: {
                      value: MAX_TITLE_LENGTH,
                      message: 'Title is too long',
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <InputGroup
                      label="Title"
                      useNaturalLettering
                      inputSize="small"
                      containerClassName="mt-3"
                      className="w-[460px] overflow-hidden"
                      onKeyDown={onEnterKeyDown}
                      onBlur={onBlur}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      value={value}
                      onChange={onChange}
                      errorMessage={error?.message}
                    />
                  )}
                />
                {followUpMode && <ActivityUseSettings />}
              </FormProvider>
            )}
          </>

          <div className="mb-8 mt-10 flex w-full flex-row justify-center">
            <Button
              title={
                !submitting
                  ? `Add to ${followUpMode ? 'follow-up' : 'library'}`
                  : 'Adding...'
              }
              onClick={handleSubmit(onSubmit, handleErrors)}
              {...(submitting && {
                IconComponent: Spinner,
                iconClassName: 'h-[16px] w-[16px]',
              })}
              disabled={Boolean(
                addActivityAudioMutationLoading ||
                  formErrors.audioFileTitle ||
                  formErrors.newAudioFile ||
                  !watchNewAudioFile ||
                  !watchAudioFileTitle,
              )}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddAudioModal;
