import classNames from 'classnames';
import { FC } from 'react';
import { PublicProviderServiceDataFragment } from '../../../generated/graphql';
import ServiceCard from '../ServiceCard';

export const DEFAULT_TAGLINE =
  'Tagline that encapsulates the support you offer';
export const DEFAULT_ABOUT =
  "Provide a succinct yet compelling narrative of your professional journey, highlighting your credentials, philosophy, and what sets you apart. Focus on why you're passionate about your niche and how your work transforms lives.";
export const DEFAULT_SERVICES =
  "Offer a clear, concise summary of your services, including session formats (individual, group, online), programs, and workshops, along with an overview of pricing. This section should invite inquiries and signal your openness to discuss how your offerings can be customized to meet clients' unique needs.";

const PractitionerInfoRightColumn: FC<{
  tagline?: string;
  about?: string;
  services?: string;
  inEditMode?: boolean;
  editModeTaglineChildren?: React.ReactNode;
  editModeAboutChildren?: React.ReactNode;
  editModeServicesChildren?: React.ReactNode;
  publicServices?: PublicProviderServiceDataFragment[];
}> = ({
  tagline,
  about,
  services,
  inEditMode,
  editModeTaglineChildren,
  editModeAboutChildren,
  editModeServicesChildren,
  publicServices,
}) => (
  <div className="flex flex-grow flex-col space-y-8 px-6 md:px-8 md:pl-14 md:pr-0">
    {!inEditMode ? (
      <div
        className={classNames(
          'mt-2 text-center font-serif text-subtitle font-light text-neutral-125',
          'md:mt-0 md:text-left md:text-title-small',
        )}
      >
        {tagline || DEFAULT_TAGLINE}
      </div>
    ) : (
      editModeTaglineChildren
    )}
    <div>
      <div
        className={classNames(
          'mb-3 cursor-default text-body font-medium text-neutral-150',
          inEditMode && ' text-neutral-100',
        )}
      >
        About
      </div>
      {!inEditMode ? (
        <div className="whitespace-pre-wrap break-words text-caption text-neutral-125">
          {about || DEFAULT_ABOUT}
        </div>
      ) : (
        editModeAboutChildren
      )}
    </div>
    {publicServices && (
      <div className="space-y-4">
        <div className="cursor-default text-body font-medium text-neutral-150">
          Services
        </div>
        <div className="flex flex-wrap justify-start gap-6">
          {publicServices?.map((publicService, index) => (
            <ServiceCard key={index} publicService={publicService} />
          ))}
        </div>
      </div>
    )}
  </div>
);

export default PractitionerInfoRightColumn;
