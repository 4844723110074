import { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

import {
  LinkIcon,
  DocumentIcon,
  PhotographIcon,
  PlusCircleIcon,
  PaperClipIcon,
} from '@heroicons/react/outline';

import Signature from '../svgs/Signature';
import Headphones from '../svgs/Headphones';
import MenuButton from './MenuButton';
import PillButton from './PillButton';

type CreateAResourceMenuProps = {
  disabled?: boolean;
  onClickAddLink?: React.MouseEventHandler<HTMLButtonElement>;
  onClickAddPDF?: React.MouseEventHandler<HTMLButtonElement>;
  onClickAddImage?: React.MouseEventHandler<HTMLButtonElement>;
  onClickAddAudio?: React.MouseEventHandler<HTMLButtonElement>;
  onClickAddText?: React.MouseEventHandler<HTMLButtonElement>;
};

const CreateAResourceMenu: FC<CreateAResourceMenuProps> = ({
  disabled = false,
  onClickAddLink,
  onClickAddImage,
  onClickAddPDF,
  onClickAddAudio,
  onClickAddText,
}) => {
  return (
    <div className="z-[5] flex w-full">
      <Menu as="div" className="relative w-full">
        <Menu.Button as={Fragment}>
          <PillButton
            IconComponent={PlusCircleIcon}
            title="Add a new resource"
            disabled={disabled}
            onClick={(e) => {
              e?.stopPropagation();
            }}
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-30 mt-4 w-72 origin-top-right divide-y divide-neutral-50 rounded-md bg-white shadow-lg focus:outline-none">
            <MenuButton
              titleText="Link"
              className="rounded-t-md"
              iconClassName="text-neutral-125"
              IconComponent={LinkIcon}
              onClick={onClickAddLink}
            />
            <MenuButton
              titleText="Image"
              iconClassName="text-neutral-125"
              IconComponent={PhotographIcon}
              onClick={onClickAddImage}
            />
            <MenuButton
              titleText="Audio"
              IconComponent={Headphones}
              iconClassName="text-neutral-125"
              onClick={onClickAddAudio}
            />
            <MenuButton
              titleText="Text document"
              className="rounded-b-md"
              iconClassName="text-neutral-125"
              IconComponent={DocumentIcon}
              onClick={onClickAddText}
            />
            <MenuButton
              titleText="File"
              iconClassName="text-neutral-125"
              IconComponent={PaperClipIcon}
              onClick={onClickAddPDF}
              helperText="E-sign option"
              HelperIconComponent={Signature}
            />
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default CreateAResourceMenu;
