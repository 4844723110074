import { useState } from 'react';
import classNames from 'classnames';

import {
  ProviderService,
  useProviderServicesQuery,
  useArchiveProviderServiceMutation,
} from '../../../../../generated/graphql';

import Spinner from '../../../../svgs/Spinner';

import ServiceCard from '../../../../components/ServiceCard';
import ConfirmDeleteModal from '../../../../components/ConfirmDeleteModal';
import { customToast } from '../../../../components/ToastAlert/customToast';
import FirstServiceCard from './FirstServiceCard';

const ServicesModule: React.FC<{
  className?: string;
}> = ({ className }) => {
  const {
    data: providerServicesData,
    refetch: refetchProviderServices,
    loading: loadingProviderServices,
  } = useProviderServicesQuery();
  const providerServices = providerServicesData?.providerServices;

  const [isDeleteServiceModalOpen, setIsDeleteServiceModalOpen] =
    useState(false);

  const [providerServiceToDelete, setProviderServiceToDelete] =
    useState<ProviderService | null>(null);

  const handleDeleteService = (service: ProviderService) => {
    setProviderServiceToDelete(service);
    setIsDeleteServiceModalOpen(true);
  };

  const [archiveProviderService, { loading: archiveProviderServiceLoading }] =
    useArchiveProviderServiceMutation();

  return (
    <>
      <div
        className={classNames(
          'px-6 py-10',
          className,
          loadingProviderServices && 'flex justify-center',
        )}
      >
        {loadingProviderServices ? (
          <Spinner className="h-6 w-6" />
        ) : !providerServices?.length ? (
          <FirstServiceCard />
        ) : (
          <div className="flex flex-wrap justify-start gap-6">
            {providerServices?.map((providerService: ProviderService) => (
              <ServiceCard
                key={providerService.id}
                service={providerService}
                handleDeleteService={handleDeleteService}
              />
            ))}
          </div>
        )}
      </div>
      <ConfirmDeleteModal
        isOpen={isDeleteServiceModalOpen}
        setClosed={() => setIsDeleteServiceModalOpen(false)}
        title={`Remove ${providerServiceToDelete?.name}`}
        fetching={loadingProviderServices || archiveProviderServiceLoading}
        actionButtonTitle={`Remove ${providerServiceToDelete?.name}`}
        performDelete={async () => {
          try {
            await archiveProviderService({
              variables: { providerServiceId: providerServiceToDelete.id },
            });
            await refetchProviderServices();
          } catch (error) {
            customToast.error('Failed to delete service');
          }
        }}
      />
    </>
  );
};

export default ServicesModule;
