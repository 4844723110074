import classNames from 'classnames';
import { DateTime } from 'luxon';

import {
  TIME_INCREMENT_OFFSET,
  TIME_INCREMENT_UNIT,
} from './ScheduledEventItem';
import { getDifferenceInMinutes } from './helpers';

const TodayTimeBar: React.FC<{
  todayTimeBarRef: React.RefObject<HTMLLIElement>;
  nowDate: DateTime;
  columnClassIndex: Record<string, string>;
}> = ({ todayTimeBarRef, nowDate, columnClassIndex }) => {
  const dayInMinutes = getDifferenceInMinutes(nowDate.startOf('day'), nowDate);

  return (
    <li
      ref={todayTimeBarRef}
      className={classNames(
        'relative z-[35] flex',
        columnClassIndex[nowDate.day],
      )}
      style={{
        gridRow: `${Math.round(
          TIME_INCREMENT_OFFSET + dayInMinutes / TIME_INCREMENT_UNIT,
        )} / span 1`,
      }}
    >
      <div className="absolute -left-[5.5px] -top-[5px] flex h-3 w-3 rounded-full bg-green-125" />
      <div className="group absolute inset-0 flex h-[2px] flex-col overflow-y-hidden bg-green-125" />
    </li>
  );
};

export default TodayTimeBar;
