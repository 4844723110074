import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BookOpenIcon,
  CalendarIcon,
  CashIcon,
  ChatIcon,
  CloudIcon,
  HomeIcon,
  MailIcon,
  MenuIcon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/outline';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { useAuth } from '../../contexts/AuthContext';

import {
  DATA_USE_URL,
  HELP_CENTER_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from '../lib/constants';

import Logout from '../svgs/Logout';
import Spinner from '../svgs/Spinner';

import Avatar from './Avatar';
import HelpQuestion from '../svgs/HelpQuestion';
import ChevronRight from '../svgs/ChevronRight';
import NotificationBell from '../svgs/NotificationBell';
import Tag from '../svgs/Tag';
import { UserType } from '../../generated/graphql';
import classNames from 'classnames';
import { isProviderUserOwnerOrAdminAuthorized } from '../../lib/auth';
import { SvgIconComponent } from '../types/svgs';
import hcLogo from '../../assets/images/logo/logo-green-xl.png';

type SettingsMenuProps = {
  name?: string;
  email?: string;
  imageUrl?: string;
  userType?: UserType;
  isMobileDevice?: boolean;
  showPatientSessions?: boolean;
  externalMode?: boolean; // When rendering an iframe, this reduces the visual footprint of the settings menu
};

interface SettingsMenuItem {
  label: string;
  IconComponent: SvgIconComponent;
  path?: string;
  onSelect?: (e?: any) => void;
  className?: string;
  iconClassName?: string;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({
  name,
  email,
  imageUrl,
  userType = UserType.ProviderUser,
  isMobileDevice = false,
  showPatientSessions = false,
  externalMode = false,
}) => {
  const navigate = useNavigate();
  const { authedProviderUser, logout } = useAuth();

  const [isLogoutLoading, setIsLogoutLoading] = useState(false);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleHelpClick = () => {
    window.open(HELP_CENTER_URL, '_blank');
  };

  const handleLogoutItemClick = async (event: Event) => {
    // Don't close dropdown menu until logout is complete.
    event.preventDefault();
    setIsLogoutLoading(true);
    await logout();
  };

  const providerListItems: SettingsMenuItem[] = authedProviderUser
    ? [
        { label: 'Account', IconComponent: UserIcon, path: 'settings' },
        {
          label: 'Notifications',
          IconComponent: NotificationBell,
          path: 'settings/notifications',
        },
        ...(isProviderUserOwnerOrAdminAuthorized(authedProviderUser)
          ? [
              {
                label: 'Team',
                IconComponent: UserGroupIcon,
                path: 'settings/team',
              },
              {
                label: 'Subscription',
                IconComponent: CashIcon,
                path: 'settings/billing',
              },
              {
                label: 'Integrations',
                IconComponent: CloudIcon,
                path: 'settings/integrations',
              },
              { label: 'Tags', IconComponent: Tag, path: 'settings/tags' },
            ]
          : []),
        {
          label: 'Help',
          IconComponent: HelpQuestion,
          onSelect: () => handleHelpClick(),
        },
        {
          label: 'Logout',
          IconComponent: isLogoutLoading ? Spinner : Logout,
          onSelect: (e) => handleLogoutItemClick(e),
          className: isLogoutLoading ? 'text-red-100' : 'text-neutral-125',
          iconClassName: !isLogoutLoading && 'text-red-100',
        },
      ]
    : [];

  const patientListItems: SettingsMenuItem[] = [
    { label: 'Home', IconComponent: HomeIcon, path: '/client/home' },
    { label: 'Mail', IconComponent: MailIcon, path: '/client/follow-ups' },
    { label: 'Chat', IconComponent: ChatIcon, path: '/client/messages' },
    ...(showPatientSessions
      ? [
          {
            label: 'Sessions',
            IconComponent: CalendarIcon,
            path: '/client/sessions',
          },
        ]
      : []),
    { label: 'Library', IconComponent: BookOpenIcon, path: '/client/library' },
  ];

  const DropdownMenuItem: React.FC<{
    label: string;
    IconComponent: React.ElementType;
    path?: string;
    onSelect?: (e?: any) => void;
    className?: string;
    iconClassName?: string;
  }> = ({ path, label, IconComponent, onSelect, className, iconClassName }) => (
    <DropdownMenu.Item
      onClick={onSelect ? onSelect : () => handleNavigate(path)}
      className={classNames(
        'flex w-full cursor-pointer flex-row items-center justify-between px-4 py-2 text-left text-caption focus:bg-neutral-50 focus:outline-none',
        'text-neutral-125',
        className,
      )}
    >
      <span>{label}</span>
      <IconComponent className={classNames('h-4 w-4', iconClassName)} />
    </DropdownMenu.Item>
  );

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <div className="flex flex-row items-center rounded-lg px-2 py-1 hover:cursor-pointer hover:bg-neutral-75">
          {!externalMode ? (
            <>
              <Avatar
                size="medium"
                className="mr-1"
                imageUrl={imageUrl}
                name={name}
              />
              <ChevronRight className="rotate-90 text-neutral-150" />
            </>
          ) : (
            <MenuIcon className="h-6 w-6 text-neutral-150" />
          )}
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="start"
          className={classNames(
            'absolute -right-10 z-50 mt-2 w-56 rounded-lg bg-white py-2 shadow-100 ring-1 ring-black ring-opacity-5 focus:outline-none',
            externalMode ? '-right-10' : '-right-[4.5rem]',
          )}
        >
          <DropdownMenu.DropdownMenuGroup>
            <div className="flex flex-row items-center justify-between border-b border-neutral-75 px-4">
              <div className="flex flex-grow flex-col items-start justify-start overflow-hidden pt-1 pb-3">
                <div className="truncate text-caption text-green-150">
                  {name}
                </div>
                <div className="w-full truncate text-small-caption text-green-125">
                  {email}
                </div>
              </div>
              {externalMode && (
                <img
                  src={hcLogo}
                  className="mb-2 ml-1 h-[1.125rem] w-[1.125rem] min-w-[1.125rem] flex-shrink-0"
                  alt="logo"
                />
              )}
            </div>
          </DropdownMenu.DropdownMenuGroup>
          {userType === UserType.ProviderUser && (
            <DropdownMenu.DropdownMenuGroup>
              {providerListItems.map((providerListItem, index) => (
                <DropdownMenuItem
                  key={`providerListItem_${index}`}
                  label={providerListItem.label}
                  IconComponent={providerListItem.IconComponent}
                  path={providerListItem.path}
                  onSelect={providerListItem.onSelect}
                  className={providerListItem.className}
                  iconClassName={providerListItem.iconClassName}
                />
              ))}
            </DropdownMenu.DropdownMenuGroup>
          )}
          {isMobileDevice && userType === UserType.Patient && (
            <DropdownMenu.DropdownMenuGroup>
              {patientListItems.map((patientListItem, index) => (
                <DropdownMenuItem
                  key={`patientListItem_${index}`}
                  label={patientListItem.label}
                  IconComponent={patientListItem.IconComponent}
                  path={patientListItem.path}
                />
              ))}
            </DropdownMenu.DropdownMenuGroup>
          )}
          <DropdownMenu.DropdownMenuGroup>
            <div className="flex flex-row items-start justify-start border-t border-neutral-75 px-4 pt-3 pb-1">
              <button
                className="mr-2 text-small-caption text-neutral-125 hover:underline"
                onClick={() => {
                  window.open(PRIVACY_POLICY_URL, '_blank');
                }}
              >
                Privacy
              </button>
              <button
                className="mr-2 text-small-caption text-neutral-125 hover:underline"
                onClick={() => {
                  window.open(TERMS_OF_SERVICE_URL, '_blank');
                }}
              >
                Terms
              </button>
              <button
                className="mr-2 text-small-caption text-neutral-125 hover:underline"
                onClick={() => {
                  window.open(DATA_USE_URL, '_blank');
                }}
              >
                Data use
              </button>
            </div>
          </DropdownMenu.DropdownMenuGroup>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default SettingsMenu;
