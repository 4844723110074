import classNames from 'classnames';

const DayLabel: React.FC<{
  dayName: string;
  dayNumber: number;
  isToday?: boolean;
}> = ({ dayName, dayNumber, isToday }) => {
  return (
    <div className="flex h-[48px] flex-row items-center justify-center py-3 text-small-caption font-medium text-neutral-125">
      <div>{dayName}</div>
      <div
        className={classNames(
          'ml-1  ',
          isToday &&
            'flex h-6 w-6 items-center justify-center rounded-full bg-green-125 text-white',
        )}
      >
        {dayNumber}
      </div>
    </div>
  );
};

export default DayLabel;
