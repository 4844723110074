import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Transition as HistoryTransition } from 'history';
import { Switch, Transition } from '@headlessui/react';
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  Controller,
} from 'react-hook-form';
import {
  ArrowNarrowLeftIcon,
  CalendarIcon,
  CheckIcon,
  DuplicateIcon,
  ExternalLinkIcon,
  EyeOffIcon,
  GiftIcon,
  PlusSmIcon,
  TrashIcon,
} from '@heroicons/react/outline';

import { resizeTextArea } from '../../lib/form';
import { defaultTransitionProps } from '../../lib/animation';

import useBlocker from '../../hooks/useBlocker';
import usePaintScreen from '../../hooks/usePaintScreen';

import Spinner from '../../svgs/Spinner';
import CalendlyLogo from '../../svgs/CalendlyLogo';
import Button from '../../components/Button';
import InputGroup from '../../components/InputGroup';
import TextAreaGroup from '../../components/TextAreaGroup';
import IconButton from '../../components/IconButton';
import ProgramTagManager from '../../components/ProgramTag/ProgramTagManager';
import UnsavedChangesModal from '../../components/Modals/UnsavedChangesModal';

import { useAuth } from '../../../contexts/AuthContext';
import { customToast } from '../../components/ToastAlert/customToast';

import {
  PatientAccessLevel,
  useCreateProviderServiceMutation,
  useProviderServiceQuery,
  useUpdateProviderServiceMutation,
  useCalendlyEventTypesQuery,
  useMeProviderProgramTagsQuery,
  ServiceTagsDataFragment,
  ActivityDataFragment,
  ActivityType,
  useActivityLazyQuery,
  useArchiveProviderServiceMutation,
  ServiceIcon,
} from '../../../generated/graphql';
import PageContainer from '../../components/Containers/PageContainer';
import IntegrationCard from '../Settings/IntegrationCard';
import Alert from '../../components/Alert';
import RadioOption from '../../components/RadioOption';
import CalendlyIcon from '../../svgs/CalendlyIcon';
import { ContentType } from '../../lib/followUp';
import AddResourceModal, {
  AddResourceModalRef,
} from '../../components/Modals/AddResourceModal';
import ContentPreview from '../../components/ContentPreview';
import ProviderActivitySlideover from '../../components/Library/ProviderActivitySlideover';

import { ProviderActivityType } from '../../types/activity';
import { SparklesIcon } from '@heroicons/react/outline';
import Checkbox from '../../components/Checkbox';
import ArrowRightLong from '../../svgs/ArrowRightLong';
import BodySectionLabel from './BodySectionLabel';
import Section from './Section';
import Gear from '../../svgs/Gear';
import classNames from 'classnames';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import {
  handleCopyMyPage,
  handleSeeMyPage,
  urlFromSlug,
} from '../../../lib/storefront';
import SelectMenu from '../../components/SelectMenu';
import { SERVICE_ICONS } from '../../components/ServiceCard/helpers';
import ServiceIconItem from './ServiceIconItem';

interface ServiceBuilderForm {
  isEnabled: boolean;
  isShownOnStorefront: boolean;
  name: string;
  serviceIcon: ServiceIcon;
  serviceIconColorUseCalendlyDefault: boolean;
  calendlyEventColor: string | null;
  slug: string;
  description: string;
  isPaid: boolean;
  price: number;
  duration: number;
  calendlyEventTypeUri: string | null;
  serviceTags: ServiceTagsDataFragment[];
  intakeFormAssessmentId: string;
  patientAccessLevel: PatientAccessLevel;
}

const MAX_NAME_LENGTH = 50;
const MAX_DESCRIPTION_LENGTH = 600;

const ServiceBuilder = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const providerServiceId = params.providerServiceId ?? '';
  const { authedProviderUser } = useAuth();
  const paintScreen = usePaintScreen();

  // State to communicate that user is coming back from the form builder, having successfully
  // created a new form to attach, so we open up the intake form module and auto-attach it.
  const intakeFormAssessmentIdToAttach = (
    location.state as { intakeFormAssessmentIdToAttach?: string }
  )?.intakeFormAssessmentIdToAttach;

  useEffect(() => {
    if (intakeFormAssessmentIdToAttach) {
      setValue('intakeFormAssessmentId', intakeFormAssessmentIdToAttach);
    }
  }, [intakeFormAssessmentIdToAttach]);

  const hasCalendlyIntegrated = Boolean(
    authedProviderUser?.provider?.calendlyIntegration,
  );

  const { data: providerServiceData, loading: providerServiceDataLoading } =
    useProviderServiceQuery({
      variables: { providerServiceId },
      skip: !providerServiceId,
    });

  const { data: calendlyEventTypeData, loading: calendlyEventTypesLoading } =
    useCalendlyEventTypesQuery({
      skip: !hasCalendlyIntegrated,
      onError: (error) => {
        customToast.error('Failed to get Calendly event types.');
      },
    });

  const calendlyEventTypes = calendlyEventTypeData?.calendlyEventTypes ?? [];

  const { data: providerTagsData, refetch: refetchProviderTags } =
    useMeProviderProgramTagsQuery();

  const [
    createProviderServiceMutation,
    { loading: createProviderServiceMutationLoading },
  ] = useCreateProviderServiceMutation();

  const [
    updateProviderServiceMutation,
    { loading: updateProviderServiceMutationLoading },
  ] = useUpdateProviderServiceMutation();

  const [
    archiveProviderServiceMutation,
    { loading: archiveProviderServiceMutationLoading },
  ] = useArchiveProviderServiceMutation();

  const [isDeleteServiceModalOpen, setIsDeleteServiceModalOpen] =
    useState(false);

  const {
    control,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<ServiceBuilderForm>({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      serviceIcon: ServiceIcon.Circles,
      serviceIconColorUseCalendlyDefault: false,
      calendlyEventColor: null,
      slug: '',
      description: '',
      duration: 30,
      isPaid: false,
      price: 0,
      calendlyEventTypeUri: null,
      serviceTags: [{ name: 'Prospect' }],
      intakeFormAssessmentId: null,
      isEnabled: true,
      isShownOnStorefront: true,
      patientAccessLevel: PatientAccessLevel.All,
    },
  });

  const showPage = paintScreen && !providerServiceDataLoading;
  const existingProviderService = providerServiceData?.providerService;

  const serviceUrl = urlFromSlug(existingProviderService?.slug);

  const hasUnsavedChanges = Boolean(isDirty);
  const [submitting, setSubmitting] = useState(false);
  const [navTransition, setNavTransition] = useState<HistoryTransition>();
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false);

  const triggerUnsavedChangesModal = Boolean(hasUnsavedChanges && !submitting);

  const blocker = useCallback(
    (navTransition: HistoryTransition) => {
      if (!isUnsavedChangesModalOpen) {
        setIsUnsavedChangesModalOpen(true);
        setNavTransition(navTransition);
      }
    },
    [triggerUnsavedChangesModal],
  );

  useBlocker(blocker, triggerUnsavedChangesModal);

  const watchName = watch('name');
  const watchDescription = watch('description');
  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);

  const nameRegister = register('name', {
    required: { value: true, message: 'Please give your service a name' },
    maxLength: {
      value: MAX_NAME_LENGTH,
      message: `Please ensure the title is less than ${MAX_NAME_LENGTH} characters`,
    },
    pattern: {
      value: /[a-zA-Z]/,
      message: 'Name must contain at least one letter',
    },
    onBlur: () => setValue('name', watchName.trim()),
  });

  const { ref: descriptionRegisterRef, ...descriptionRegister } = register(
    'description',
    {
      maxLength: {
        value: MAX_DESCRIPTION_LENGTH,
        message: `Please ensure the description is less than ${MAX_DESCRIPTION_LENGTH} characters`,
      },
      pattern: {
        value: /[a-zA-Z]/,
        message: 'Description must contain at least one letter',
      },
      onChange: () => resizeTextArea(descriptionRef),
      onBlur: () => setValue('description', watchDescription.trim()),
    },
  );

  const watchIsPaid = watch('isPaid');
  const watchIsEnabled = watch('isEnabled');
  const watchCalendlyEventTypeUri = watch('calendlyEventTypeUri');
  const watchServiceIconColorUseCalendlyDefault = watch(
    'serviceIconColorUseCalendlyDefault',
  );
  const watchCalendlyEventColor = watch('calendlyEventColor');

  const priceRegister = register('price', {
    valueAsNumber: true,
    min: { value: 0, message: 'Price cannot be negative' },
  });

  useEffect(() => {
    resizeTextArea(descriptionRef);
  }, [descriptionRegisterRef]);

  useEffect(() => {
    if (existingProviderService) {
      const {
        isEnabled,
        isShownOnStorefront,
        patientAccessLevel,
        slug,
        name,
        description,
        isPaid,
        serviceIcon,
        serviceIconColorUseCalendlyDefault,
        calendlyEventColor,
        price,
        durationMinutes,
        serviceTags,
        intakeFormAssessment,
        calendlyEventTypeUri,
      } = existingProviderService;

      setValue('isEnabled', isEnabled);
      setValue('isShownOnStorefront', isShownOnStorefront);
      setValue('patientAccessLevel', patientAccessLevel);
      setValue('slug', slug);
      setValue('name', name);
      setValue('serviceIcon', serviceIcon);
      setValue(
        'serviceIconColorUseCalendlyDefault',
        serviceIconColorUseCalendlyDefault,
      );
      setValue('calendlyEventColor', calendlyEventColor);
      setValue('description', description);
      setValue('isPaid', isPaid);
      setValue('price', price);
      setValue('duration', durationMinutes);
      setValue('intakeFormAssessmentId', intakeFormAssessment?.id);
      setValue('serviceTags', serviceTags);
      setValue('calendlyEventTypeUri', calendlyEventTypeUri);
    }
  }, [existingProviderService, setValue]);

  const onSubmit: SubmitHandler<ServiceBuilderForm> = async (data) => {
    setSubmitting(true);

    try {
      const input = {
        isEnabled: data.isEnabled,
        isShownOnStorefront: !data.isEnabled ? false : data.isShownOnStorefront,
        patientAccessLevel: data.patientAccessLevel,
        name: data.name,
        serviceIcon: data.serviceIcon,
        serviceIconColorUseCalendlyDefault:
          data.serviceIconColorUseCalendlyDefault,
        calendlyEventColor: data.calendlyEventColor,
        description: data.description,
        isPaid: data.isPaid,
        price: data.price,
        durationMinutes: data.duration,
        serviceTags: data.serviceTags.map((tag) => {
          const { name, id } = tag;
          return { name, id };
        }),
        intakeFormAssessmentId: data.intakeFormAssessmentId,
        calendlyEventTypeUri: data.calendlyEventTypeUri,
      };

      if (providerServiceId) {
        await updateProviderServiceMutation({
          variables: { input: { providerServiceId, ...input } },
          onCompleted: (data) => {
            if (data?.updateProviderService) {
              customToast.success(
                `Successfully updated ${data.updateProviderService.name}!`,
              );
            }
          },
        });
      } else {
        await createProviderServiceMutation({
          variables: { input },
          onCompleted: (data) => {
            if (data?.createProviderService) {
              customToast.success(
                `Successfully created ${data.createProviderService.name}!`,
              );
            }
          },
        });
      }
      navigate('/my-page');
    } catch (err) {
      console.error('Errors submitting:', err);
      customToast.error('Something went wrong - please try again later.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleErrors: SubmitErrorHandler<ServiceBuilderForm> = (errors) => {
    console.error('Errors submitting:', errors);
  };

  const isLoading = providerServiceDataLoading || calendlyEventTypesLoading;
  const isSaving =
    createProviderServiceMutationLoading ||
    updateProviderServiceMutationLoading;

  const addResourceModalRef = useRef<AddResourceModalRef | null>(null);
  const [isAddResourceModalOpen, setIsAddResourceModalOpen] = useState(false);
  const [isProviderActivitySlideoverOpen, setIsProviderActivitySlideoverOpen] =
    useState(false);

  const [getActivityQuery, { data: activityData }] = useActivityLazyQuery();
  const selectedProviderActivity = activityData?.activity;

  const selectedProviderActivityAssessment = selectedProviderActivity as {
    __typename?: 'ActivityAssessment';
    assessment: { __typename?: 'Assessment'; name: string };
  };

  const formName = selectedProviderActivityAssessment?.assessment?.name;

  const watchIntakeFormAssessmentId = watch('intakeFormAssessmentId');

  useEffect(() => {
    if (watchIntakeFormAssessmentId) {
      getActivityQuery({
        variables: {
          activityId: watchIntakeFormAssessmentId,
          activityType: ActivityType.Assessment,
        },
      });
    }
  }, [watchIntakeFormAssessmentId]);

  const handleProviderActivitiesSelected = async (
    providerActivities: ActivityDataFragment[],
  ) => {
    const providerActivity = providerActivities?.[0];
    if (
      !providerActivity ||
      providerActivity.__typename !== ProviderActivityType.Assessment
    ) {
      return;
    }

    setValue('intakeFormAssessmentId', providerActivity?.id);
  };

  const handleDeleteService = async () => {
    try {
      await archiveProviderServiceMutation({
        variables: { providerServiceId },
      });
    } catch (err) {
      customToast.error('Something went wrong - please try again later.');
    } finally {
      navigate('/my-page');
    }
  };

  const [isCopyingLink, setIsCopyingLink] = useState(false);

  return (
    <div className="relative scroll-smooth">
      <Transition show={showPage} {...defaultTransitionProps}>
        <div className="t-16 fixed z-10 flex w-full flex-row items-center justify-between border-b border-neutral-50 bg-white py-3 px-20">
          <div className="w-[350px]">
            <IconButton
              IconComponent={ArrowNarrowLeftIcon}
              iconClassName="text-green-100 w-5"
              aria-label="Back"
              className="rounded-xl px-3 py-2 ring-0"
              onClick={() => navigate(-1)}
            >
              <div className="small-caption ml-2 font-bold text-green-100">
                Back
              </div>
            </IconButton>
          </div>
          <span className="text-category font-medium text-green-150 line-clamp-1">
            {Boolean(watchName) ? watchName : 'Untitled service'}
          </span>
          <div className="flex w-[350px] items-center justify-end">
            {isSaving && <Spinner className="mr-2 h-5 w-5 text-neutral-125" />}
            {serviceUrl && (
              <>
                <Button
                  title="Open service page"
                  IconComponent={watchIsEnabled ? ExternalLinkIcon : EyeOffIcon}
                  iconPosition="left"
                  theme="secondary-white"
                  size="small"
                  className="mr-0.5"
                  disabled={!watchIsEnabled}
                  onClick={() => handleSeeMyPage(serviceUrl)}
                  circular
                />
                <Button
                  title="Copy link"
                  IconComponent={isCopyingLink ? CheckIcon : DuplicateIcon}
                  iconClassName={isCopyingLink && 'text-green-100'}
                  iconPosition="left"
                  size="small"
                  theme="secondary-white"
                  className="mr-3"
                  disabled={!watchIsEnabled}
                  onClick={() => {
                    setIsCopyingLink(true);
                    handleCopyMyPage(serviceUrl);
                    setTimeout(() => {
                      setIsCopyingLink(false);
                    }, 3000);
                  }}
                  circular
                />
              </>
            )}
            <Button
              title="Save"
              size="small"
              className="px-6 py-1.5"
              onClick={handleSubmit(onSubmit, handleErrors)}
              disabled={isSaving || isLoading}
            />
          </div>
        </div>
      </Transition>
      <PageContainer
        noPadding
        containerClassName="bg-white min-h-[calc(100vh-48px)] h-auto items-start pl-24 pt-24 pb-16"
      >
        <Transition
          beforeEnter={() => {
            window.scrollTo(0, 0);
          }}
          show={showPage}
          {...defaultTransitionProps}
        >
          {/* Main Content */}
          {/* Scrollable Content */}
          <div>
            <div className="max-w-[1000px] space-y-12">
              {/* Payment & Booking Section */}
              <Section
                title="Event selection"
                IconComponent={CalendarIcon}
                SectionDetail={
                  <div className="mt-4 flex flex-col items-start justify-start space-y-2">
                    <div className="text-caption text-neutral-125">
                      How to...
                    </div>
                    <a
                      className="cursor-pointer text-caption text-blue-100 hover:underline"
                      href="https://help.calendly.com/hc/en-us/p/video-tutorials?wchannelid=zs2tmj7w77&wmediaid=utmnja6ezz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Setup events in Calendly
                    </a>
                    <div className="flex flex-col items-start justify-start space-y-1">
                      <div className="mt-4 text-caption text-neutral-125">
                        Accept payments in Calendly
                      </div>
                      <a
                        className="cursor-pointer text-caption text-blue-100 hover:underline"
                        href="https://help.calendly.com/hc/en-us/p/video-tutorials?wchannelid=zs2tmj7w77&wmediaid=licnc5y7gt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        via Stripe
                      </a>
                      <a
                        className="cursor-pointer text-caption text-blue-100 hover:underline"
                        href="https://help.calendly.com/hc/en-us/p/video-tutorials?wchannelid=zs2tmj7w77&wmediaid=4uyfnxvgld"
                        target="_blank"
                        rel="noreferrer"
                      >
                        via PayPal
                      </a>
                    </div>
                  </div>
                }
              >
                <div className="">
                  {!hasCalendlyIntegrated ? (
                    <IntegrationCard
                      logo={<CalendlyLogo className="h-8" />}
                      name="Calendly"
                      description="Sync your calendar with Homecoming and allow clients to schedule sessions."
                      isConnected={hasCalendlyIntegrated}
                      integrationPath="/integrations/calendly"
                    />
                  ) : (
                    <>
                      <BodySectionLabel
                        className="mb-4"
                        text="Select a Calendly event for automated scheduling and payments"
                      />
                      <div className="space-y-5">
                        {calendlyEventTypesLoading ? (
                          <Spinner />
                        ) : calendlyEventTypes.length === 0 ? (
                          <Alert
                            level="warning"
                            message="No event types found"
                          />
                        ) : (
                          <form>
                            <Controller
                              control={control}
                              name="calendlyEventTypeUri"
                              render={({ field }) => (
                                <div className="space-y-6">
                                  <RadioOption
                                    id="eventType_none"
                                    value={undefined}
                                    name={field.name}
                                    onChange={async (e) => {
                                      field.onChange(e);
                                    }}
                                    onBlur={field.onBlur}
                                    checked={field.value === undefined}
                                    labelContent={
                                      <div className="flex items-center">
                                        <span className="mx-3 inline-block h-4 w-4 rounded-full bg-neutral-110" />
                                        No event
                                      </div>
                                    }
                                  />
                                  {Boolean(calendlyEventTypes.length) && (
                                    <div className="w-full border-b border-neutral-75"></div>
                                  )}
                                  {calendlyEventTypes
                                    .sort((a, b) => a.duration - b.duration)
                                    .map((eventType, index) => (
                                      <RadioOption
                                        key={`calendlyEventType_${index}`}
                                        id={`eventType_${eventType.name}`}
                                        value={eventType.calendlyEventTypeUri}
                                        name={field.name}
                                        onChange={async (e) => {
                                          field.onChange(e);
                                          setValue('name', eventType.name);
                                          setValue(
                                            'duration',
                                            eventType.duration,
                                          );
                                          if (
                                            watchServiceIconColorUseCalendlyDefault
                                          ) {
                                            setValue(
                                              'calendlyEventColor',
                                              eventType.color,
                                            );
                                          } else {
                                            setValue(
                                              'calendlyEventColor',
                                              null,
                                            );
                                          }
                                        }}
                                        onBlur={field.onBlur}
                                        checked={
                                          field.value ===
                                          eventType.calendlyEventTypeUri
                                        }
                                        labelContent={
                                          <div className="flex items-center">
                                            <span
                                              className="mx-3 inline-block h-4 w-4 rounded-full"
                                              style={{
                                                backgroundColor:
                                                  eventType.color,
                                              }}
                                            />
                                            <div className="flex flex-row items-center justify-start">
                                              <div className="text-caption">
                                                {eventType.name}
                                              </div>
                                              <div className="ml-3 mt-0.5 text-small-caption text-neutral-125">
                                                {eventType.duration} minutes
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      />
                                    ))}
                                </div>
                              )}
                            />
                          </form>
                        )}
                      </div>

                      <BodySectionLabel
                        text="Set up payments or add a new event in Calendly"
                        className="mt-8"
                      />
                      <Button
                        title="Calendly settings"
                        size="small"
                        theme="secondary"
                        iconPosition="left"
                        onClick={() =>
                          window.open(
                            'https://calendly.com/event_types/user/me',
                            '_blank',
                          )
                        }
                        IconComponent={CalendlyIcon}
                      />
                    </>
                  )}
                </div>
              </Section>

              <Section title="Details" IconComponent={GiftIcon}>
                <div className="flex flex-row items-start justify-start">
                  <InputGroup
                    required
                    label="Title"
                    useNaturalLettering
                    type="text"
                    inputSize="small"
                    errorMessage={errors.name?.message}
                    characterCounter
                    containerClassName="w-full mr-6"
                    currentLengthValue={watchName?.length}
                    maxLengthValue={MAX_NAME_LENGTH}
                    {...nameRegister}
                  />
                  <Controller
                    name="serviceIcon"
                    control={control}
                    defaultValue={undefined}
                    rules={{
                      required: {
                        value: true,
                        message: 'Choose an icon',
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <SelectMenu
                        label="Icon"
                        buttonClassName="max-h-12"
                        onChange={(selectedOption: { icon: ServiceIcon }) =>
                          onChange(selectedOption.icon)
                        }
                        fieldValue={
                          value
                            ? {
                                icon: value,
                                isCalendlyDefaultColor:
                                  watchServiceIconColorUseCalendlyDefault,
                                color: watchCalendlyEventColor,
                              }
                            : null
                        }
                        fieldOptions={SERVICE_ICONS.map((icon) => ({
                          icon,
                          isCalendlyDefaultColor:
                            watchServiceIconColorUseCalendlyDefault,
                          color: watchCalendlyEventColor,
                        }))}
                        SelectOptionComponent={ServiceIconItem}
                      />
                    )}
                  />
                  {Boolean(watchCalendlyEventTypeUri) && (
                    <div className="ml-6 flex w-[120px] flex-col items-center justify-start">
                      <div className="mb-3 text-left text-caption text-green-150">
                        Event color
                      </div>
                      <Switch
                        checked={watchServiceIconColorUseCalendlyDefault}
                        onChange={async () => {
                          setValue(
                            'calendlyEventColor',
                            watchCalendlyEventColor,
                          );
                          setValue(
                            'serviceIconColorUseCalendlyDefault',
                            !watchServiceIconColorUseCalendlyDefault,
                          );
                        }}
                        className={classNames(
                          !watchServiceIconColorUseCalendlyDefault
                            ? 'bg-neutral-100'
                            : 'bg-green-125',
                          'relative inline-flex h-[31px] w-[58px] flex-shrink-0 cursor-pointer rounded-full',
                          'transition duration-200 ease-in-out focus:outline-none focus:ring-0',
                        )}
                      >
                        <div
                          className={classNames(
                            !watchServiceIconColorUseCalendlyDefault
                              ? 'translate-x-0'
                              : 'translate-x-full',
                            'pointer-events-none absolute top-[2px] left-0.5 inline-block h-[27px] w-[27px]',
                            'transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                          )}
                        />
                      </Switch>
                    </div>
                  )}
                </div>
                <TextAreaGroup
                  label="Description"
                  value={watchDescription}
                  errorMessage={errors.description?.message}
                  characterCounter
                  currentLengthValue={watchDescription?.length}
                  maxLengthValue={MAX_DESCRIPTION_LENGTH}
                  {...descriptionRegister}
                  ref={(event) => {
                    descriptionRegisterRef(event);
                    descriptionRef.current = event;
                  }}
                />

                {Boolean(watchCalendlyEventTypeUri) && (
                  <div className="flex h-[72px] w-full flex-row justify-start">
                    <div className="mt-5 mr-8 flex flex-row items-center">
                      <div className="mr-4 text-caption text-green-150">
                        Paid service
                      </div>
                      <Switch
                        checked={watchIsPaid}
                        onChange={async () => {
                          setValue('isPaid', !watchIsPaid);
                        }}
                        className={classNames(
                          !watchIsPaid ? 'bg-neutral-100' : 'bg-green-125',
                          'relative inline-flex h-[31px] w-[58px] flex-shrink-0 cursor-pointer rounded-full',
                          'transition duration-200 ease-in-out focus:outline-none focus:ring-0',
                        )}
                      >
                        <div
                          className={classNames(
                            !watchIsPaid ? 'translate-x-0' : 'translate-x-full',
                            'pointer-events-none absolute top-[2px] left-0.5 inline-block h-[27px] w-[27px]',
                            'transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                          )}
                        />
                      </Switch>
                    </div>
                    <div className="flex flex-row items-start justify-start">
                      {watchIsPaid && (
                        <InputGroup
                          type="number"
                          label="Price ($)"
                          inputSize="small"
                          className="w-[150px]"
                          useNaturalLettering
                          {...priceRegister}
                        />
                      )}
                    </div>
                  </div>
                )}
              </Section>

              <Section title="Settings" IconComponent={Gear}>
                <div className="space-y-12">
                  <div>
                    <BodySectionLabel text="Tag clients who book this service" />
                    <Controller
                      name="serviceTags"
                      control={control}
                      render={({ field }) => (
                        <ProgramTagManager
                          selectedProgramTags={field.value}
                          onTagsChange={async (tags) => field.onChange(tags)}
                          availableProgramTags={
                            providerTagsData?.meProvider?.provider
                              ?.programTags ?? []
                          }
                          refreshAvailableProgramTags={refetchProviderTags}
                          minimumProgramTagCount={1}
                          maximumProgramTagCount={5}
                          className="min-w-[220px]"
                          alwaysIncludeAddTagButton
                          wrapTags
                        />
                      )}
                    />
                  </div>

                  <div>
                    <BodySectionLabel text="Add an intake form" />
                    <div className="space-y-6">
                      {!watchIntakeFormAssessmentId ? (
                        <div className="flex gap-4">
                          <Button
                            title="Add existing Intake Form"
                            theme="secondary"
                            iconPosition="left"
                            size="small"
                            IconComponent={PlusSmIcon}
                            onClick={() => setIsAddResourceModalOpen(true)}
                          />
                          <Button
                            title="Create a new Intake Form"
                            size="small"
                            theme="secondary-white"
                            IconComponent={ArrowRightLong}
                            className="group hover:bg-white"
                            iconClassName="opacity-0 group-hover:opacity-100"
                            onClick={() =>
                              navigate('/library/forms/new', {
                                state: {
                                  fromPage: 'my-page/service/new',
                                },
                              })
                            }
                          />
                        </div>
                      ) : (
                        <div className="flex flex-row items-center space-x-10">
                          <ContentPreview
                            className="max-w-[500px] border border-neutral-75 !p-4 shadow-none"
                            contentType={ContentType.Form}
                            title={formName}
                            onClick={() =>
                              setIsProviderActivitySlideoverOpen(true)
                            }
                            onClickCloseX={() =>
                              setValue('intakeFormAssessmentId', null)
                            }
                          />
                          <Button
                            title="Preview"
                            theme="secondary-white"
                            className="group hover:bg-white"
                            iconClassName="opacity-0 group-hover:opacity-100"
                            IconComponent={ArrowRightLong}
                            noBackground
                            noOutline
                            onClick={() =>
                              setIsProviderActivitySlideoverOpen(true)
                            }
                          />
                        </div>
                      )}

                      <div className="flex items-start text-neutral-125 md:w-full md:max-w-[500px]">
                        <SparklesIcon className="mr-2 mt-1 h-5 w-5 flex-shrink-0" />
                        <div className="text-caption">
                          Homecoming collects{' '}
                          <span className="font-medium">
                            full names and emails
                          </span>{' '}
                          directly from your prospective clients, so you can
                          skip these details on your Intake Form.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-8">
                    <div className="flex flex-row items-center justify-start">
                      <div className="mr-8 flex flex-row items-center space-x-3">
                        <Switch
                          checked={watchIsEnabled}
                          onChange={async () => {
                            setValue('isEnabled', !watchIsEnabled);
                          }}
                          className={classNames(
                            !watchIsEnabled ? 'bg-neutral-100' : 'bg-green-125',
                            'relative inline-flex h-[31px] w-[58px] flex-shrink-0 cursor-pointer rounded-full',
                            'transition duration-200 ease-in-out focus:outline-none focus:ring-0',
                          )}
                        >
                          <div
                            className={classNames(
                              !watchIsEnabled
                                ? 'translate-x-0'
                                : 'translate-x-full',
                              'pointer-events-none absolute top-[2px] left-0.5 inline-block h-[27px] w-[27px]',
                              'transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                            )}
                          />
                        </Switch>
                        <div className="text-caption text-green-150">
                          Publish to web
                        </div>
                      </div>

                      {watchIsEnabled && (
                        <Controller
                          name="isShownOnStorefront"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id="isShownOnStorefront"
                              checked={field.value}
                              checkboxClassName="mr-3"
                              onChange={(e) => field.onChange(e.target.checked)}
                              labelContent="Show on My Page"
                            />
                          )}
                        />
                      )}
                    </div>

                    <div>
                      <BodySectionLabel text="This service can be booked by:" />
                      <Controller
                        name="patientAccessLevel"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <div className="space-y-4">
                            <Checkbox
                              id="patientAccessLevel-all"
                              type="radio"
                              value={PatientAccessLevel.All}
                              checked={value === PatientAccessLevel.All}
                              onChange={() => onChange(PatientAccessLevel.All)}
                              labelContent={
                                <div className="ml-2 text-caption">
                                  All clients
                                </div>
                              }
                            />
                            <Checkbox
                              id="patientAccessLevel-current"
                              type="radio"
                              value={PatientAccessLevel.Current}
                              checked={value === PatientAccessLevel.Current}
                              onChange={() =>
                                onChange(PatientAccessLevel.Current)
                              }
                              labelContent={
                                <div className="ml-2 text-caption">
                                  Current clients
                                </div>
                              }
                            />
                            <Checkbox
                              id="patientAccessLevel-new"
                              type="radio"
                              value={PatientAccessLevel.New}
                              checked={value === PatientAccessLevel.New}
                              onChange={() => onChange(PatientAccessLevel.New)}
                              labelContent={
                                <div className="ml-2 text-caption">
                                  New clients
                                </div>
                              }
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </Section>
              {existingProviderService && (
                <Section>
                  <Button
                    title="Delete service"
                    theme="destructive"
                    iconPosition="left"
                    IconComponent={TrashIcon}
                    size="small"
                    onClick={() => setIsDeleteServiceModalOpen(true)}
                  />
                </Section>
              )}
            </div>
          </div>
        </Transition>

        {!showPage && <Spinner className="mx-auto mt-24" />}
      </PageContainer>
      <UnsavedChangesModal
        isModalOpen={isUnsavedChangesModalOpen}
        setClosed={() => setIsUnsavedChangesModalOpen(false)}
        onConfirm={() => navTransition?.retry()}
      />
      <ConfirmDeleteModal
        isOpen={isDeleteServiceModalOpen}
        setClosed={() => setIsDeleteServiceModalOpen(false)}
        title={`Delete ${watchName}`}
        fetching={archiveProviderServiceMutationLoading}
        actionButtonTitle="Delete"
        performDelete={handleDeleteService}
      />
      <AddResourceModal
        isModalOpen={isAddResourceModalOpen}
        setClosed={() => setIsAddResourceModalOpen(false)}
        contentTypeFilterLock={ContentType.Form}
        restrictSingleSelection
        onAddProviderActivities={handleProviderActivitiesSelected}
        ref={addResourceModalRef}
        ignoreMultiSignatureForms
      />
      <ProviderActivitySlideover
        isOpen={isProviderActivitySlideoverOpen}
        onClose={() => setIsProviderActivitySlideoverOpen(false)}
        selectedProviderActivity={selectedProviderActivity}
        followUpMode
      />
    </div>
  );
};

export default ServiceBuilder;
