import React, { useEffect, useState } from 'react';
import {
  PatientAccessLevel,
  usePublicProviderServiceQuery,
  PublicServiceSignUpError,
  usePublicServiceSignUpMutation,
} from '../../../generated/graphql';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HOMEPAGE_URL } from '../../lib/constants';
import PageContainer from '../../components/Containers/PageContainer';
import useDisableOverscroll from '../../hooks/useDisableOverscroll';
import Loading from '../Loading';
import { Transition } from '@headlessui/react';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import { defaultTransitionProps } from '../../lib/animation';

import usePaintScreen from '../../hooks/usePaintScreen';
import Avatar from '../../components/Avatar';
import classNames from 'classnames';
import {
  CALENDLY_COLOR_MAP,
  getIconComponent,
} from '../../components/ServiceCard/helpers';

import ErrorMessage from '../../components/ErrorMessage';
import InputGroup from '../../components/InputGroup';
import { NAME_REGEX, EMAIL_REGEX } from '../../lib/regex';
import { DEFAULT_CTA_BUTTON_TEXT } from '../MyPage';
import { useForm } from 'react-hook-form';
import { usePatientAuth } from '../../../contexts/PatientAuthContext';
import PublicPageFooter from '../../components/PublicPageFooter';
import ServiceIntakeCompleteModal from '../../components/Modals/ServiceIntakeCompleteModal';
import Spinner from '../../svgs/Spinner';

interface PublicServiceSignUpFormData {
  fullName: string;
  email: string;
}

const MOBILE_DESCRIPTION_MAX_LENGTH = 300;

const PublicServicePage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  useDisableOverscroll();
  const paintScreen = usePaintScreen();
  const { updateTokenPayload } = usePatientAuth();
  const [checkYourEmail, setCheckYourEmail] = useState(false);

  const slug = params.slug;
  const serviceSlug = params.serviceSlug;

  const fromIntakeSuccess = (location.state as { fromIntakeSuccess?: boolean })
    ?.fromIntakeSuccess;

  const { data: providerServiceData, loading: providerServiceLoading } =
    usePublicProviderServiceQuery({
      variables: {
        slug,
        serviceSlug,
      },
      skip: !slug || !serviceSlug,
      onError: () => {
        window.location.href = HOMEPAGE_URL;
      },
    });

  const providerService = providerServiceData?.publicProviderService;

  const showPage = paintScreen && !providerServiceLoading;

  const isNotAllAccess =
    providerService?.patientAccessLevel !== PatientAccessLevel.All;
  const isNewPatientsAccess =
    providerService?.patientAccessLevel === PatientAccessLevel.New;
  const isCurrentPatientsAccess =
    providerService?.patientAccessLevel === PatientAccessLevel.Current;

  const IconComponent = getIconComponent(providerService?.serviceIcon);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors: formErrors, isSubmitting: isFormSubmitting },
  } = useForm<PublicServiceSignUpFormData>();

  const [publicServiceSignUp, { loading: isPublicServiceSignUpLoading }] =
    usePublicServiceSignUpMutation();

  const onSubmit = async (formData: PublicServiceSignUpFormData) => {
    if (!slug || !serviceSlug) {
      return;
    }

    const { email, fullName } = formData;

    try {
      const { data } = await publicServiceSignUp({
        variables: {
          input: {
            email,
            name: fullName,
            storefrontSlug: slug,
            serviceSlug,
          },
        },
      });

      const publicServiceSignUpData = data?.publicServiceSignUp;

      if (publicServiceSignUpData?.error) {
        const publicServiceSignUpError = publicServiceSignUpData.error;
        switch (publicServiceSignUpError) {
          case PublicServiceSignUpError.InvalidServiceSlug:
            setError('root.serverError', {
              message: `We couldn't find the service you're trying to connect with. Please try again later.`,
            });
            break;
          case PublicServiceSignUpError.EmailAlreadyExistsOtherProvider:
            setError('root.serverError', {
              message: `It looks like you're already working with another provider on Homecoming. Please provide another email to work with ${providerService?.providerName}`,
            });
            break;
          case PublicServiceSignUpError.EmailAlreadyExistsThisProvider:
            setCheckYourEmail(true);
            break;
          case PublicServiceSignUpError.CurrentClientsOnly:
            setError('root.serverError', {
              message: `It looks like you're a new client. This service is only available for current clients.`,
            });
            break;
          case PublicServiceSignUpError.NewClientsOnly:
            setError('root.serverError', {
              message: `It looks like you're an existing client. This service is only available for new clients.`,
            });
            break;
          default:
            break;
        }
        return;
      }

      if (publicServiceSignUpData?.authToken) {
        updateTokenPayload({
          authSessionToken: publicServiceSignUpData.authToken,
        });
        navigate(
          `/client/intake/${publicServiceSignUpData.serviceIntakePackageId}`,
          {
            state: {
              fullName,
            },
          },
        );
      }
    } catch (error) {
      setError('root.serverError', {
        message:
          "Something went wrong. Please contact support@homecoming.health and we'll help you get connected.",
      });
    }
  };

  const [
    isServiceIntakeCompleteModalOpen,
    setIsServiceIntakeCompleteModalOpen,
  ] = useState(false);

  useEffect(() => {
    if (providerService && fromIntakeSuccess) {
      setIsServiceIntakeCompleteModalOpen(true);
      const timeout = setTimeout(() => {
        if (providerService.patientAccessLevel === PatientAccessLevel.Current) {
          navigate('/client', { replace: true });
        }
      }, 3500);
      return () => clearTimeout(timeout);
    }
  }, [providerService, fromIntakeSuccess, navigate]);

  const [showFullDescription, setShowFullDescription] = useState(false);

  const isStorefrontEnabled = providerService?.storefrontEnabled;

  return (
    <>
      {providerService ? (
        <div
          className={classNames('relative scroll-smooth', 'min-h-[100vh-64px]')}
        >
          {isStorefrontEnabled && (
            <Transition show={showPage} {...defaultTransitionProps}>
              <div className="t-16 fixed z-10 flex w-full flex-row items-center justify-start border-b border-neutral-50 bg-white py-3 px-6 md:px-20">
                <div className="w-[289px]">
                  <IconButton
                    IconComponent={ArrowLeftIcon}
                    iconClassName="text-green-100 w-5"
                    aria-label="See my homepage"
                    className="rounded-xl px-3 py-2"
                    onClick={() => navigate(`/p/${slug}`)}
                  >
                    <div className="small-caption ml-2 font-bold text-green-100">
                      Back to my homepage
                    </div>
                  </IconButton>
                </div>
              </div>
            </Transition>
          )}

          <PageContainer
            containerClassName={classNames(
              'h-[calc(100vh-var(--top-nav-height))] md:overflow-y-hidden',
              'pb-8 md:pb-0 px-8 pt-16 md:px-24 min-w-full',
            )}
            noPadding
          >
            <div className="flex h-full w-full flex-col gap-6 py-8 md:flex-row md:gap-12">
              {/* Left side - Service Info */}
              <div className="flex-1">
                <div className="mb-10 flex items-center gap-4">
                  <Avatar
                    imageUrl={providerService.profileImageUrl}
                    name={providerService.providerName}
                    size="medium-large"
                  />
                  <div className="text-neutral-125">
                    <div className="font-serif text-subtitle-small font-light">
                      {providerService.providerName}
                    </div>
                    <div className="text-small-caption">
                      {providerService.providerRoleTitle}
                    </div>
                  </div>
                </div>

                <div className="mb-12 mt-4">
                  <div className="mb-6 flex w-full items-center justify-between">
                    <div className="flex flex-row items-end justify-start">
                      <IconComponent
                        className={classNames(
                          'h-12 w-12',
                          providerService.serviceIconColorUseCalendlyDefault &&
                            providerService.calendlyEventColor
                            ? CALENDLY_COLOR_MAP[
                                providerService.calendlyEventColor
                              ]
                            : 'text-blue-100',
                        )}
                      />
                      {isNotAllAccess && (
                        <div
                          className={classNames(
                            'ml-3 rounded-full px-3 py-1 text-small-caption',
                            isNewPatientsAccess && 'bg-green-25 text-green-150',
                            isCurrentPatientsAccess &&
                              'bg-blue-25 text-blue-150',
                          )}
                        >
                          {isNewPatientsAccess && 'New clients only'}
                          {isCurrentPatientsAccess && 'Current clients only'}
                        </div>
                      )}
                    </div>
                    <div className="text-category font-medium text-green-150">
                      {providerService.isPaid && providerService.price
                        ? `$${providerService.price}`
                        : 'Free'}
                    </div>
                  </div>
                  <div className="mb-2 font-serif text-subtitle font-light">
                    {providerService.name}
                  </div>
                  <p className="mb-4 text-small-caption text-neutral-110">
                    {providerService.durationMinutes} minutes
                  </p>
                  <div className="md:hidden">
                    {showFullDescription ? (
                      <p className="whitespace-pre-wrap break-words text-caption text-neutral-125">
                        {providerService.description}
                      </p>
                    ) : (
                      <p className="whitespace-pre-wrap break-words text-caption text-neutral-125">
                        {providerService.description.slice(
                          0,
                          MOBILE_DESCRIPTION_MAX_LENGTH,
                        )}
                        {providerService.description.length >
                          MOBILE_DESCRIPTION_MAX_LENGTH && (
                          <>
                            {'... '}
                            <button
                              onClick={() => setShowFullDescription(true)}
                              className="inline font-medium text-green-100"
                            >
                              Read more
                            </button>
                          </>
                        )}
                      </p>
                    )}
                  </div>
                  <p className="hidden whitespace-pre-wrap break-words text-caption text-neutral-125 md:block">
                    {providerService.description}
                  </p>
                </div>
              </div>

              {/* Right side - Booking Form Container */}
              <div className="flex h-full flex-1 flex-col items-center justify-center">
                <div className="flex h-[90%] w-full flex-col justify-center rounded-lg bg-white p-8 shadow-lg">
                  <div className="flex w-full flex-col items-center">
                    <div className="mb-12 flex flex-col items-center justify-start gap-1 text-neutral-125">
                      <div className="text-subtitle">Get started</div>
                      <div className="text-caption">Enter your details</div>
                    </div>
                    <form className="w-full">
                      <InputGroup
                        placeholder="Full name"
                        autoComplete="name"
                        type="text"
                        inputSize="small"
                        useNaturalLettering={true}
                        containerClassName="mb-4"
                        required
                        errorMessage={formErrors.fullName?.message}
                        {...register('fullName', {
                          required: 'Name is required',
                          pattern: {
                            value: NAME_REGEX,
                            message: 'Please enter your first and last name',
                          },
                        })}
                      />
                      <InputGroup
                        placeholder="Email"
                        autoComplete="email"
                        type="email"
                        inputSize="small"
                        useNaturalLettering={true}
                        containerClassName="mb-4"
                        required
                        errorMessage={formErrors.email?.message}
                        {...register('email', {
                          required: 'Email is required',
                          pattern: {
                            value: EMAIL_REGEX,
                            message: 'Please enter a valid email',
                          },
                        })}
                      />
                      <Button
                        title={DEFAULT_CTA_BUTTON_TEXT}
                        className="mx-auto my-6 w-full disabled:cursor-default md:mt-8"
                        type="submit"
                        disabled={isFormSubmitting}
                        onClick={handleSubmit(onSubmit)}
                        {...(isPublicServiceSignUpLoading && {
                          IconComponent: Spinner,
                        })}
                      />
                      {/* Error message, but also handle the case where we've sent the email text*/}
                      <ErrorMessage
                        className="mb-4 w-full"
                        textClassName={classNames(
                          'text-center w-full',
                          !formErrors.root?.serverError &&
                            checkYourEmail &&
                            'text-green-125',
                        )}
                      >
                        {formErrors.root?.serverError?.message ||
                          (checkYourEmail &&
                            "We've sent you an email with a link to your client portal.")}
                      </ErrorMessage>
                    </form>
                  </div>
                </div>
              </div>
              <PublicPageFooter className="pb-8 md:hidden" />
            </div>
          </PageContainer>
          <PublicPageFooter className="hidden md:flex" />
        </div>
      ) : (
        <Loading />
      )}
      <ServiceIntakeCompleteModal
        isModalOpen={isServiceIntakeCompleteModalOpen}
        setClosed={() => setIsServiceIntakeCompleteModalOpen(false)}
        avatarImageUrl={providerService?.profileImageUrl}
        avatarName={providerService?.providerName}
      />
    </>
  );
};

export default PublicServicePage;
