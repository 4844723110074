import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Button from '../../../components/Button';

interface SeeAllButtonProps {
  title: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const SeeAllButton: React.FC<SeeAllButtonProps> = ({ title, onClick }) => (
  <Button
    theme="alt"
    className="mb-6 !p-0 text-neutral-125/80 hover:text-neutral-150 md:mb-0"
    noFocus
    title={title}
    IconComponent={ChevronDownIcon}
    iconClassName="text-green-100/50 group-hover:text-green-100 -rotate-90"
    iconPosition="right"
    onClick={onClick}
  />
);

export default SeeAllButton;
