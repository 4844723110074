import { ProgramAssessmentDataFragment } from '../../../generated/graphql';
import { getTimeStamp } from '../../lib/time';
import { customToast } from '../ToastAlert/customToast';

export const handlePdfDownload = async (
  blob: Blob | null,
  programAssessment: ProgramAssessmentDataFragment,
  patientName: string,
) => {
  if (blob) {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;

    const dateCompleted = getTimeStamp(programAssessment?.completedAt, false);
    const title = programAssessment?.assessment?.name;

    const clientName = patientName || 'Client';
    link.download = `${clientName} - ${title} - ${dateCompleted}.pdf`;

    link.click();
    URL.revokeObjectURL(url);
    customToast.success('PDF downloaded successfully');
  }
};
