import React, { useEffect, useState } from 'react';
import { MODAL_TRANSITION_DURATION } from '../../../components/Modal';
import ModalDialog from '../../../components/ModalDialog';
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import InputGroup from '../../../components/InputGroup';

interface NameDocumentModalFormData {
  name: string;
}

const MIN_DOCUMENT_NAME_LENGTH = 1;
const MAX_DOCUMENT_NAME_LENGTH = 80;

const NameDocumentModal: React.FC<{
  isOpen: boolean;
  setClosed: () => void;
  documentName?: string;
  handleSaveDocument: (documentName: string) => void;
}> = ({ isOpen, setClosed, documentName, handleSaveDocument }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors: formErrors },
    reset,
    resetField,
    setFocus,
  } = useForm<NameDocumentModalFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      name: documentName ?? '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (formData: NameDocumentModalFormData) => {
    try {
      setIsLoading(true);

      const newDocumentName = formData.name.trim();
      await handleSaveDocument(newDocumentName);

      resetField('name', { defaultValue: newDocumentName }); // Necessary to reset default form value
      closeAndResetModal();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onErrors: SubmitErrorHandler<NameDocumentModalFormData> = (errors) => {
    console.log('errors:', errors);
  };

  const closeAndResetModal = () => {
    setClosed();
    setTimeout(() => {
      reset({ name: documentName ?? '' });
    }, MODAL_TRANSITION_DURATION);
  };

  // Ensure default name input stays synced with a provided mail template name
  useEffect(() => {
    setValue('name', documentName ?? '');
  }, [documentName, setValue]);

  // Auto-focus name input requires waiting for modal to animate open
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setFocus('name');
      }, MODAL_TRANSITION_DURATION);
    }
  }, [isOpen, setFocus]);

  return (
    <ModalDialog
      isOpen={isOpen}
      setClosed={closeAndResetModal}
      width="xs"
      title={'Name your file'}
      primaryActionTitle={!isLoading ? 'Upload' : 'Uploading'}
      primaryActionOnClick={handleSubmit(onSubmit, onErrors)}
      primaryActionDisabled={isLoading}
      primaryActionOnEnter={true}
    >
      <div className="flex h-16 flex-col">
        <InputGroup
          inputSize="small"
          labelHidden
          containerClassName="w-full"
          {...register('name', {
            required: {
              value: true,
              message: 'Please name your file',
            },
            minLength: {
              value: MIN_DOCUMENT_NAME_LENGTH,
              message: `Name must be at least ${MIN_DOCUMENT_NAME_LENGTH} characters`,
            },
            maxLength: {
              value: MAX_DOCUMENT_NAME_LENGTH,
              message: `Name must be less than ${MAX_DOCUMENT_NAME_LENGTH} characters`,
            },
          })}
          errorMessage={formErrors.name?.message}
          maxLength={MAX_DOCUMENT_NAME_LENGTH}
        />
      </div>
    </ModalDialog>
  );
};

export default NameDocumentModal;
