import React, { useState } from 'react';
import { usePopper } from 'react-popper';

import classNames from 'classnames';
import { Listbox, Portal } from '@headlessui/react';
import { CALENDAR_HEX_COLOR_MAP } from '../../../lib/colors';

type ColorSelectorProps = {
  selectedColor: string;
  onColorChange?: (color: string) => Promise<void>;
  className?: string;
};

const ColorSelector: React.FC<ColorSelectorProps> = ({
  selectedColor,
  onColorChange,
  className,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
  });

  return (
    <Listbox
      as="div"
      value={selectedColor}
      className="relative"
      onChange={onColorChange}
    >
      <Listbox.Button
        className={classNames(
          'h-7 w-7 rounded-full border-2 border-neutral-110',
          className,
        )}
        style={{ backgroundColor: selectedColor }}
        ref={setReferenceElement}
      />
      <Portal>
        <Listbox.Options
          className="absolute z-50 divide-y divide-neutral-75 rounded-md border border-neutral-75 bg-white shadow-lg focus:outline-none"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {Object.entries(CALENDAR_HEX_COLOR_MAP).map(
            ([colorName, hexColor]) => (
              <Listbox.Option
                key={colorName}
                value={hexColor}
                className={({ active }) =>
                  classNames(
                    'flex cursor-pointer flex-row items-center px-5 py-3 text-neutral-150',
                    active && 'bg-neutral-25',
                  )
                }
              >
                <div
                  className={classNames(
                    'mr-3 h-5 w-5 min-w-[1.25rem] rounded-full',
                  )}
                  style={{ backgroundColor: hexColor }}
                />
                <div className="truncate text-caption">{colorName}</div>
              </Listbox.Option>
            ),
          )}
        </Listbox.Options>
      </Portal>
    </Listbox>
  );
};

export default ColorSelector;
